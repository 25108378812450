import { useEffect } from "react";
import { Pagination } from "react-bootstrap";
import { ReactComponent as StepLeft } from "../assets/icon/svgs/solid/backward-step.svg";
import { ReactComponent as Left } from "../assets/icon/svgs/solid/caret-left.svg";
import { ReactComponent as StepRight } from "../assets/icon/svgs/solid/forward-step.svg";
import { ReactComponent as Right } from "../assets/icon/svgs/solid/caret-right.svg";
import "./pagination.scss";

const PaginationAPIWrap = ({
  pageNumber,
  setpageNumber,
  lastpage,
  setActiveNum,
  activeNum,
}) => {
  useEffect(() => {
    if (lastpage < 5) {
      var arr = [];
      for (var i = 0; i < lastpage; i++) {
        arr.push(i + 1);
        setpageNumber(arr);
      }
    } else {
      setpageNumber([1, 2, 3, 4, 5]);
    }
    if (activeNum <= 3 && lastpage > 5) {
      setpageNumber([1, 2, 3, 4, 5]);
    } else if (activeNum > 3 && activeNum < lastpage - 2 && lastpage > 5) {
      setpageNumber([
        activeNum - 2,
        activeNum - 1,
        activeNum,
        activeNum + 1,
        activeNum + 2,
      ]);
    } else if (activeNum >= lastpage - 2 && lastpage > 5) {
      setpageNumber([
        lastpage - 4,
        lastpage - 3,
        lastpage - 2,
        lastpage - 1,
        lastpage,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNum, lastpage]);
  return (
    <Pagination className="m-0 d-flex justify-content-center align-items-center">
      <Pagination.Item
        className="pagination_item"
        onClick={() => setActiveNum(1)}
        disabled={activeNum <= 1}
      >
        <StepLeft style={{ width: "14px", height: "16px" }} />
      </Pagination.Item>
      <Pagination.Item
        className="pagination_item"
        onClick={() => setActiveNum(activeNum - 1)}
        disabled={activeNum <= 1}
      >
        <Left style={{ width: "14px", height: "16px" }} />
      </Pagination.Item>
      {pageNumber?.map((pageNum) => (
        <Pagination.Item
          key={pageNum}
          className="pagination_item"
          onClick={() => setActiveNum(pageNum)}
          active={activeNum === pageNum}
        >
          {pageNum}
        </Pagination.Item>
      ))}
      <Pagination.Item
        className="pagination_item"
        onClick={() => setActiveNum(activeNum + 1)}
        disabled={activeNum >= lastpage}
      >
        <Right style={{ width: "14px", height: "16px" }} />
      </Pagination.Item>
      <Pagination.Item
        className="pagination_item"
        onClick={() => setActiveNum(lastpage)}
        disabled={activeNum >= lastpage}
      >
        <StepRight style={{ width: "14px", height: "16px" }} />
      </Pagination.Item>
    </Pagination>
  );
};

export default PaginationAPIWrap;
