import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { adminUserPut } from "../../../apis/request";

function EndDateModal({ setModal, date, id, recallApi }) {
  const [isMo, setIsMo] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [drop, setDrop] = useState("");
  const [message, setMessage] = useState("");
  const [endDate, setEndDate] = useState("");
  const textareaRef = useRef(null);
  useEffect(() => {
    if (isMobile) {
      setIsMo(true);
    } else {
      setIsMo(false);
    }
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  useEffect(() => {
    if (date) {
      setEndDate(date);
    }
  }, [date]);

  const callApi = async () => {
    try {
      let body = {
        id: id,
        endDate: endDate,
      };
      await adminUserPut("/api/subscription/update", body);
      alert("요청 성공 하였습니다.");
      recallApi();
      setModal(false);
    } catch (error) {
      if (error.data.error) {
        alert(error.data.error);
      } else if (error.data.message) {
        alert(error.data.message);
      } else {
        alert("요청 실패 하였습니다.");
      }
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto"; // 높이 초기화
      const newHeight = Math.min(textarea.scrollHeight, 5 * 24); // 최대 5줄까지 허용
      textarea.style.height = `${newHeight}px`; // 새로운 높이 설정
    }
  }, [message]);

  const handleDate = (e) => {
    if (e.target.value) {
      setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
      setShowPlaceholder(false);
    } else {
      setEndDate("");
      setShowPlaceholder(true);
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card
              className="modalCard w-100"
              style={{
                padding: "36px 20px 45px",
                borderRadius: "8px",
              }}
            >
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                >
                  종료일을 변경하시겠습니까?
                </Col>
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumSubText"
                  style={{ marginBottom: "10px" }}
                >
                  아래 날짜를 선택해 주세요.
                </Col>
              </Row>
              <Row className="w-100 px-0" style={{ margin: "30px 0px" }}>
                <Col lg={12} className="p-0 mx-0">
                  <div
                    className="input-group h-100"
                    style={{
                      position: "relative",
                      paddingLeft: "1px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {isMo
                      ? showPlaceholder && (
                          <label
                            htmlFor="date"
                            style={{
                              position: "absolute",
                              left: "10px",
                              top: "0",
                              color: "#888",
                              pointerEvents: "none",
                              userSelect: "none",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              margin: "0px",
                              zIndex: 10,
                              backgroundColor: "#fff",
                            }}
                          >
                            연도.월.일.
                          </label>
                        )
                      : null}
                    <input
                      type="date"
                      className="js-input-mask form-control"
                      name="firstDate"
                      id="firstDateLabel"
                      // min={moment().format("YYYY-MM-DD")}
                      max="9999-12-31"
                      // className="js-input-mask form-control customSearchInput h-100"
                      value={endDate}
                      onChange={handleDate}
                      style={{
                        maxWidth: "15rem",
                      }}
                      data-placeholder="날짜 선택"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 4px 0px 0px" }}>
                  <button id="primiumRejectBtn" onClick={() => setModal(false)}>
                    취소
                  </button>
                </Col>
                <Col className="m-0" style={{ padding: "0px 0px 0px 4px" }}>
                  <Button id="primiumAccesBtn" onClick={() => callApi()}>
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default EndDateModal;
