import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

//Import Breadcrumb

function Education({ editData }) {
  return (
    <React.Fragment>
      <h2>학력 정보</h2>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      {editData?.education?.map((item, i) => {
        return (
          <Row className="rowBorderBottom mt-3" key={i}>
            <h4>{item?.type}</h4>
            <Col md="10">
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom04">이름 :</Label>
                <div>{item?.name}</div>
              </FormGroup>
            </Col>
            <Col md="10">
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom04">주소 :</Label>
                <div>{item?.address?.address}</div>
              </FormGroup>
            </Col>
            <Col md="10">
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom04">상세주소 :</Label>
                <div>{item?.address?.detail}</div>
              </FormGroup>
            </Col>
            <Col md="10">
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom04">우편번호 :</Label>
                <div>{item?.address?.postNum}</div>
              </FormGroup>
            </Col>
            <Col md="10">
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom04">전공 :</Label>
                <div>{item?.major}</div>
              </FormGroup>
            </Col>
            <Col md="10">
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom04">상태 :</Label>
                <div>{item?.status}</div>
              </FormGroup>
            </Col>
            <Col md="10">
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom04">입학연도 :</Label>
                <div>{item?.admyear}</div>
              </FormGroup>
            </Col>
            <Col md="10">
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom04">졸업연도 :</Label>
                <div>{item?.gradyear}</div>
              </FormGroup>
            </Col>
          </Row>
        );
      })}
    </React.Fragment>
  );
}

export default Education;
