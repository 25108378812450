import React from "react";
import { Col, Row } from "reactstrap";

function Profile({ handleChange }) {
  return (
    <React.Fragment>
      <h3 className="MainText">본인 상세정보</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label htmlFor="myhobbyLabel" className="labelText">
              관심사/취미
            </label>
            <input
              type="text"
              className="js-input-mask form-control customInput"
              name="myhobby"
              id="myhobbyLabel"
              placeholder="관심사/취미을(를) 입력해 주세요."
              aria-label="관심사/취미을(를) 입력해 주세요."
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label htmlFor="mystyleLabel" className="labelText">
              성격
            </label>
            <textarea
              className="js-input-mask form-control customInput"
              name="mystyle"
              id="mystyleLabel"
              placeholder="성격을(를) 입력해 주세요."
              aria-label="성격을(를) 입력해 주세요."
              style={{ height: "300px", resize: "none" }}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Profile;
