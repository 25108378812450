import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { adminRequestPost } from "../../../apis/request";
// UR4370
const OneMoreIntroModal = ({
  Msg,
  setMsg,
  id,
  targetCode,
  isPending,
  sendDate,
  setValues,
  values,
}) => {
  const [value, setValue] = useState(null);
  const callApi = async () => {
    try {
      let body = {
        userId: id,
        targetCode: targetCode,
        isPending: isPending,
        sendDate: sendDate,
      };
      await adminRequestPost("/api/introduce/force", body);
      alert("요청 성공 하였습니다.");
      setValues({
        ...values,
        targetCode: "",
      });
      window.location.reload();
    } catch (error) {
      if (error.data.error) {
        setValues({
          ...values,
          targetCode: "",
        });
        alert(error.data.error);
        setMsg(null);
      } else if (error.data.message) {
        setValues({
          ...values,
          targetCode: "",
        });
        alert(error.data.message);
        setMsg(null);
      } else {
        alert("요청 실패 하였습니다.");
        setMsg(null);
      }
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard" style={{ padding: "36px 24px" }}>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                >
                  {Msg}
                </Col>
              </Row>
              <Row className="pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      사유
                    </label>
                    <div
                      className="js-input-mask introlabelInput"
                      style={{
                        padding: "0px 14px",
                        background: "#F3F3F3",
                        boxShadow: "none",
                        marginBottom: "10px",
                      }}
                    >
                      <select
                        id="counselor_genderLabel"
                        className="customselect1"
                        style={{
                          width: "100%",
                          border: "none",
                          padding: "14px 0px",
                          background: "none",
                          color: "#333",
                        }}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      >
                        {!value ? (
                          <option value="">선택해주세요.</option>
                        ) : null}
                        <option value="기존 관계를 유지하고 소개 보내기">
                          기존 관계를 유지하고 소개 보내기
                        </option>
                        <option
                          value="기존 관계를 해제하고 소개 보내기
"
                        >
                          기존 관계를 해제하고 소개 보내기
                        </option>
                      </select>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 4px 0px 0px" }}>
                  <button id="primiumRejectBtn" onClick={() => setMsg(null)}>
                    취소
                  </button>
                </Col>
                <Col className="m-0" style={{ padding: "0px 0px 0px 4px" }}>
                  <Button id="primiumAccesBtn" onClick={() => callApi()}>
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default OneMoreIntroModal;
