import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "./modal.scss";
import {
  adminRequestPost,
  adminUserPut,
  requestGet,
} from "../../../apis/request";
import { set } from "lodash";
//lch1721 2024년 8월 1일 이후 신규 로직에 의한 인센티브 상세 목록 
function NewSubscribeModal({
  setModal,
  data
}) {
  const [value, setValue] = useState([]);
  const formatDate = (dateString) => {
    if(dateString === null || dateString === undefined) return "";
    try{  
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Intl.DateTimeFormat('ko-KR', options).format(new Date(dateString));
    }catch(e){
      return "";
    }
  };

  // 가격을 3자리마다 쉼표를 추가하여 포맷팅하는 함수
  const formatPrice = (price) => {
    if(price === null || price === undefined) return "";
    try {
      return new Intl.NumberFormat('ko-KR', { style: 'decimal' }).format(Math.round(price)) + "원";  
    } catch (error) {
      return price;  
    }
    
  };


  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);



  const onClose = async () => {
    setModal(false);
  };

  const displayUsedDays = (usedDays) => {
    if(usedDays === null || usedDays === undefined) return "";
    try{
      return Number(usedDays) >= 0 ? usedDays : '';
    }catch(e){
      return "";
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="12" md="11" lg="9" xl="8">
            <div className="modalOverlay" />
            <Card className="modalCard py-3 ps-3 pe-2">
              <h2>기간제 결제 내역</h2>
              <table striped className="mt-3 border-table" >
                <thead>
                  <tr>
                    <th>사용자</th>
                    <th>상품명</th>
                    <th>가격</th>
                    <th>시작일</th>
                    <th>종료일</th>
                    <th>총일수</th>
                    <th>휴면일</th>
                    <th>사용일</th>
                    <th>환불 유무</th>
                    <th>인센티브</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td>{item.userName}</td>
                      <td>{item.name}</td>
                      <td style={{ textAlign: 'right' }} >{formatPrice(item.price)}</td>
                      <td>{formatDate(item.startDate)}</td>
                      <td>{item.endDate? formatDate(item.endDate):null}</td>
                      <td>{item.totalDays}</td>
                      {/* item.incen 이 - 이면 7월 구매의 환불이니  item.sleepDays 를 빈칸 */}
                      <td>{displayUsedDays(item.sleepDays)}</td>
                      <td>{displayUsedDays(item.usedDays)}</td>
                      <td>{item.isRefund  && item.isRefund === true ? "환불" :""}</td>
                      <td style={{ textAlign: 'right' }} >{formatPrice(item.incen)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row className="w-100 m-0 mt-3">
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    color="danger"
                    style={{ marginRight: "10px" }}
                    onClick={() => onClose()}
                  >
                    닫기
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default NewSubscribeModal;
