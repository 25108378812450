import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

function Money({ editData }) {
  return (
    <React.Fragment>
      <h2>자산정보</h2>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      <Row className="rowBorderBottom mt-3">
        <h4>본인</h4>
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom05">주거유형</Label>
            <div>{editData?.assets[0]?.housingType}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">소유여부</Label>
            <div>{editData?.assets[0]?.owneType}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">주거평수</Label>
            <div>{editData?.assets[0]?.housingArea}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">동산</Label>
            <div>{editData?.assets[0]?.movableAmount}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">부동산</Label>
            <div>{editData?.assets[0]?.immovableAmount}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">차소유여부</Label>
            <div>{editData?.assets[0]?.hasCar ? "유" : "무"}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <h4>부모</h4>
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom05">주거유형</Label>
            <div>{editData?.assets[0]?.housingType}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">소유여부</Label>
            <div>{editData?.assets[0]?.owneType}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">주거평수</Label>
            <div>{editData?.assets[0]?.housingArea}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">동산</Label>
            <div>{editData?.assets[0]?.movableAmount}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">부동산</Label>
            <div>{editData?.assets[0]?.immovableAmount}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">차소유여부</Label>
            <div>{editData?.assets[0]?.hasCar ? "유" : "무"}</div>
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Money;
