import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody } from "reactstrap";

import { adminUserPut, requestGet } from "../../apis/request";

import { useHistory } from "react-router-dom";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";

function RegMemberList(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);

  const history = useHistory();

  const handleAcceptClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    try {
      let body = {
        id: row.id,
      };
      await adminUserPut(`/api/member/join/accept`, body);
      alert("승인 성공 하였습니다.");
      window.location.reload();
    } catch (error) {
      alert("승인 실패 하였습니다.");
    }
  };

  const handlePendingClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    try {
      let body = {
        id: row.id,
      };
      await adminUserPut(`/api/member/join/pending`, body);
      alert("보류 성공 하였습니다.");
      window.location.reload();
    } catch (error) {
      alert("보류 실패 하였습니다.");
    }
  };

  const handleRejectClick = async (e, row, rowIndex) => {
    // 여기에 클릭 시 실행할 로직 추가
    try {
      let body = {
        id: row.id,
      };
      await adminUserPut(`/api/member/join/reject`, body);
      alert("거절 성공 하였습니다.");
      window.location.reload();
    } catch (error) {
      alert("거절 실패 하였습니다.");
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "이름",
      sort: true,
    },
    {
      dataField: "phone",
      text: "연락처",
      sort: true,
    },
    {
      dataField: "recommender",
      text: "추천인",
      sort: true,
    },
    {
      dataField: "gender",
      text: "성별",
      sort: true,
    },
    {
      dataField: "birthday",
      text: "생년월일",
      sort: true,
    },
    {
      dataField: "regDate",
      text: "가입일시",
      sort: true,
    },
    {
      dataField: "action",
      text: "승인",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => handleAcceptClick(e, row, rowIndex)}
            className="btn btn-primary btn-sm"
          >
            승인
          </button>
        );
      },
    },
    {
      dataField: "action",
      text: "보류",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => handlePendingClick(e, row, rowIndex)}
            className="btn btn-primary btn-sm"
          >
            보류
          </button>
        );
      },
    },
    {
      dataField: "action",
      text: "거절",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => handleRejectClick(e, row, rowIndex)}
            className="btn btn-primary btn-sm"
          >
            거절
          </button>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;

  const callInfo = async () => {
    const memberResponse = await requestGet(`/api/member/join/list`);
    setMemberList(memberResponse?.data);
  };

  useEffect(() => {
    callInfo();
  }, []);

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          name: item.name,
          birthday: item.birthday,
          gender: item.gender,
          phone: item.phone,
          recommender: item.recommender,
          regDate: item.regDate,
          recommendCode: item.recommendCode,
          recommendId: item.recommendId,
        })
      );
      setProductData(arr);
    }
  }, [memberList]);

  const handleRowClick = (e, row) => {
    window.location.href = `/user/${row.id}/signup`;
  };

  // rowEvents 객체 정의
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowClick(e, row, rowIndex);
    },
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>가입요청 회원 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h2>가입요청 회원 목록</h2>
                  <p className="card-title-desc"></p>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    rowEvents={rowEvents}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RegMemberList;
