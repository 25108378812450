import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";

import { requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";

function LikeMemberList(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);

  const { id } = useParams();
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "targetCode",
      text: "상대 코드",
      sort: true,
    },
    // {
    //   dataField: "phone",
    //   text: "전화번호",
    //   sort: true,
    // },
    // {
    //   dataField: "recommender",
    //   text: "추천인",
    //   sort: true,
    // },
    // {
    //   dataField: "gender",
    //   text: "성별",
    //   sort: true,
    // },
    // {
    //   dataField: "birthday",
    //   text: "생년월일",
    //   sort: true,
    // },
    // {
    //   dataField: "grade",
    //   text: "등급",
    //   sort: true,
    // },
    // {
    //   dataField: "received",
    //   text: "받은호감",
    //   sort: true,
    // },
    // {
    //   dataField: "sent",
    //   text: "보낸호감",
    //   sort: true,
    // },
    // {
    //   dataField: "regDate",
    //   text: "가입일시",
    //   sort: true,
    // },
    // {
    //   dataField: "isSleep",
    //   text: "휴면여부",
    //   sort: true,
    // },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;

  const callInfo = useCallback(async () => {
    const memberResponse = await requestGet(`/api/like/list/${id}`);
    setMemberList(memberResponse?.data);
  }, [id]);

  useEffect(() => {
    if (id) {
      callInfo();
    }
  }, [callInfo, id]);

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          name: item.name,
          birthday: item.birthday,
          gender: item.gender,
          phone: item.phone,
          recommender: item.recommender,
          regDate: item.regDate,
          received: item.received,
          grade: item.grade,
          sent: item.sent,
          targetCode: item.targetCode,
          isSleep: item.isSleep ? "휴면" : "정상",
        })
      );
      setProductData(arr);
    }
  }, [memberList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>호감 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <h2>호감 목록</h2>
                  <p className="card-title-desc"></p>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LikeMemberList;
