import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { adminUserPut, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import { useIntroMemberListStore} from "../../store/zustand/state";

//lch1721 - 소개 된 목록 탭

function HaveBeenIntroducedList({ id, callAPI, resetAPI }) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(1);
  const [lastpage, setLastpage] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const { list, updateList, updateActiveNum } = useIntroMemberListStore();

  const [isBeforeApiCalled  , setBeforeApiCalled] = useState(1);

  const columns = [
    {
      dataField: "introduceeName",
      text: "이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
            href={`/member/detail/${row.introduceeId}`}
            style={{ color: "#495057" }}
            target="_blank"
            rel="noreferrer"
          >
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "introduceeGender",
      text: "성별",
      sort: true,
    },
    {
      dataField: "introduceeGrade",
      text: "등급",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "소개 일자",
      sort: true,
    },
    {
      dataField: "introduceStatus",
      text: "상태",
      sort: true,
    },
    {
      dataField: "cancleBtn",
      text: "예약취소",
      formatter: (cellContent, row, rowIndex) => {
        if (row.introduceStatus === "예약 발송") {
          return (
            <button
              onClick={(e) => handleRejectClick(row.id)}
              className="btn btn-danger btn-sm"
            >
              예약취소
            </button>
          );
        }
      },
    },
    {
      dataField: "sendDate",
      text: "예약 시간",
      sort: true,
    },
    {
      dataField: "requestTime",
      text: "발송 시간",
      sort: true,
    },
    {
      dataField: "sendStatus",
      text: "알림톡 발송 완료",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };
  const handleRejectClick = async (id) => {
    if (window.confirm("예약 발송 취소 하시겠습니까?")) {
      try {
        let body = {
          id: id,
        };
        const response = await adminUserPut(`/api/introduce/cancel`, body);
        alert("예약 발송 취소 하였습니다.");
        callAPI();
        callInfo();
      } catch (error) {
        console.log(error);
        alert("다시 시도해 주세요.");
      }
    }
  };
  const callInfo = useCallback(async () => {
    const params = new URLSearchParams();
    if (list) {
      for (var key in list) {
        if (list[key]) {
          params.append(key, list[key]);
        }
      }
    }
    const memberResponse = await requestGet(`/api/introduce/list/target/${id}?${params}`);
    console.log("memberResponse", memberResponse);
    setMemberList(memberResponse?.data);
    setLastpage(memberResponse?.totalPage);
    setTotalCount(memberResponse?.totalCount);
  }, [list]);

  useEffect(() => {
    setActiveNum(list.page);
    if (id && resetAPI) {
      callInfo();
    }
  }, [callInfo, id, resetAPI, list]);

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          introduceeGender: item.recipientGender,
          introduceeName: item.recipientName,
          createdAt: item.createdAt,
          requestTime: item.requestTime,
          introduceeGrade: item.recipientGrade,
          introduceeId: item.recipientId,
          sendDate: item.introduceStatus === "pending" ? item.sendDate : "",
          sendStatus: item.sendStatus ? "완료" : "미완료",
          introduceStatus:
            item.introduceStatus === "request"
              ? "발송"
              : item.introduceStatus === "pending"
              ? "예약 발송"
              : item.introduceStatus === "accept"
              ? "수락"
              : item.introduceStatus === "reject"
              ? "거절"
              : item.introduceStatus === "cancel"
              ? "취소"
              : "",
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">소개된 목록</h3>
      <p className="card-title-desc"></p>
      <ToolkitProvider
          keyField="id"
          columns={columns}
          data={productData}
          search
      >
        {(toolkitProps) => (
            <React.Fragment>
              <Row>
                <Col xl="12">
                  <div className="text-end customTotalcount">
                    검색결과 (
                    {String(totalCount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    개의 검색결과가 있습니다)
                  </div>
                  <div className="table-responsive">
                    <BootstrapTable
                        keyField={"id"}
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                    />
                  </div>
                </Col>
              </Row>
              {/* <div ref={btnref} style={{ height: "auto" }}> */}
              {!lastpage ? null : (
                  <Row className="w-100 m-0 p-0 py-3">
                    <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                      <PaginationAPIWrap
                          pageNumber={pageNumber}
                          setpageNumber={setpageNumber}
                          lastpage={lastpage}
                          activeNum={activeNum}
                          setActiveNum={updateActiveNum}
                      />
                    </Col>
                    <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                      <MobilePaginationAPIWrap
                          pageNumber={mobilepageNumber}
                          setpageNumber={setMobilepageNumber}
                          lastpage={lastpage}
                          activeNum={activeNum}
                          setActiveNum={updateActiveNum}
                      />
                    </Col>
                  </Row>
              )}
            </React.Fragment>
        )}
      </ToolkitProvider>
    </React.Fragment>
  );
}

export default HaveBeenIntroducedList;
