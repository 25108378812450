import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "./modal.scss";
import {
  adminRequestPost,
  adminUserPut,
  requestGet,
} from "../../../apis/request";

function CashHandModal({ setModal, userId, callInfo, callAPI }) {
  const [value, setValue] = useState("");
  const [directType, setDirectType] = useState("");
  const [directCardCompany, setDirectCardCompany] = useState("");
  const [directCardMonthlyPayment, setDirectCardMonthlyPayment] = useState("");
  const [directPaymentNumber, setDirectPaymentNumber] = useState("");
  const [memo, setMemo] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [time, setTime] = useState("");
  const [amount, setAmount] = useState("");
  const [desc, setDesc] = useState("");

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const firstCallApi = async () => {
    if (!name) {
      return alert("상품 이름을 입력해 주세요.");
    }
    if (!type) {
      return alert("종류를 선택해 주세요.");
    }
    if (!price) {
      return alert("가격을 입력해 주세요.");
    }
    if (type === "subscribe" && !time) {
      return alert("기간을 입력해 주세요.");
    }
    if ((type === "joinFee" || type === "purchaseItem") && !amount) {
      return alert("매칭권 개수를 입력해 주세요.");
    }
    try {
      let body = {
        name: name,
        type: type,
        price: Number(price.replace(/\D/g, "")),
        desc: desc,
      };
      if (type === "subscribe") {
        body.days = Number(time);
      } else if (type === "joinFee" || type === "purchaseItem") {
        body.likeCoupon = Number(amount);
      }
      const response = await adminRequestPost("/api/product/direct", body);
      callApi(response.data.id);
    } catch (error) {
      alert(error?.data?.message);
    }
  };

  const callApi = async (id) => {
    try {
      let body = {
        userId: userId,
        productId: id,
        memo: memo,
        directType: directType,
        directPrice: price,
        directCardCompany: directCardCompany,
        directCardMonthlyPayment: Number(directCardMonthlyPayment),
        directPaymentNumber: directPaymentNumber,
        status: "complete",
      };
      await adminRequestPost("/api/purchase/direct", body);
      callInfo();
      alert("생성 완료 되었습니다.");
      if (callAPI) {
        callAPI();
      }
      setModal(false);
    } catch (error) {
      console.log(error);
      alert("생성 실패 하였습니다.");
      setModal(false);
    }
  };

  const onClose = async () => {
    setModal(false);
  };

  const onChangePrice = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    const formattedNumber = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setPrice(formattedNumber);
  };
  function numberToKorean(number) {
    let num = number ? number : "0";
    if (typeof number === "string") {
      num = number.replace(/\D/g, "");
    }
    const units = ["", "만", "억", "조", "경"];
    const nums = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
    let result = "";
    let unit = 10000;

    if (num === 0) {
      return "영";
    }

    for (let u of units) {
      const curr = num % unit;
      num = Math.floor(num / unit);

      if (curr !== 0) {
        let currStr = "";
        const currArr = String(curr).split("").reverse();

        for (let i = 0; i < currArr.length; i++) {
          const digit = currArr[i];
          if (digit !== "0") {
            currStr =
              nums[parseInt(digit)] +
              (i > 0 ? ["", "십", "백", "천"][i] : "") +
              currStr;
          }
        }
        result = currStr + u + result;
      }

      if (num === 0) {
        break;
      }
    }

    // "일"을 제거하는 조건을 보다 구체적으로 설정하여, "일억"과 같은 경우에는 "일"을 제거하지 않음
    return (result += "원");
  }
  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard py-3 ps-3 pe-2">
              <h2>결제 조회</h2>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      상품 이름
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="상품 이름을 입력해 주세요."
                      aria-label="상품 이름을 입력해 주세요."
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      종류
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      {type === "" ? (
                        <option value="">선택해주세요.</option>
                      ) : null}
                      <option value="subscribe">기간제</option>
                      <option value="joinFee">가입비</option>
                      <option value="marriageFee">성혼사례비</option>
                      <option value="purchaseItem">추가매칭권</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {type === "subscribe" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        기간(일)
                      </label>
                      <input
                        type="number"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="기간을 입력해 주세요."
                        aria-label="기간을 입력해 주세요."
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {type === "joinFee" || type === "purchaseItem" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        매칭권 개수
                      </label>
                      <input
                        type="number"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="매칭권 개수를 입력해 주세요."
                        aria-label="매칭권 개수를 입력해 주세요."
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      가격
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="name"
                      id="nameLabel"
                      placeholder="가격을 입력해 주세요."
                      aria-label="가격을 입력해 주세요."
                      value={price}
                      onChange={(e) => onChangePrice(e)}
                    />
                    <div>{numberToKorean(price)}</div>
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText" for="marketing_agree">
                      설명
                    </label>
                    <textarea
                      className="js-input-mask form-control customInput"
                      value={desc}
                      placeholder={`설명을 입력해 주세요.`}
                      aria-label={`설명을 입력해 주세요.`}
                      onChange={(e) => setDesc(e.target.value)}
                      rows={5}
                      style={{ resize: "none" }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText">결제 타입</label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      value={directType}
                      onChange={(e) => setDirectType(e.target.value)}
                    >
                      {!directType ? (
                        <option value="">선택해주세요.</option>
                      ) : null}
                      <option value="card">카드</option>
                      <option value="bank">계좌이체</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {directType === "card" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        카드사
                      </label>
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="카드사를 입력해 주세요."
                        aria-label="카드사를 입력해 주세요."
                        value={directCardCompany}
                        onChange={(e) => setDirectCardCompany(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {directType === "card" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        할부
                      </label>
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="할부를 입력해 주세요."
                        aria-label="할부를 입력해 주세요."
                        value={directCardMonthlyPayment}
                        onChange={(e) =>
                          setDirectCardMonthlyPayment(e.target.value)
                        }
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {directType === "card" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        카드번호
                      </label>
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="카드번호를 입력해 주세요."
                        aria-label="카드번호를 입력해 주세요."
                        value={directPaymentNumber}
                        onChange={(e) => setDirectPaymentNumber(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText">메모</label>
                    <textarea
                      className="js-input-mask form-control customInput"
                      placeholder="메모를 입력해 주세요."
                      aria-label="메모를 입력해 주세요."
                      rows={5}
                      value={memo}
                      style={{ resize: "none" }}
                      onChange={(e) => setMemo(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-3">
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    color="danger"
                    style={{ marginRight: "10px" }}
                    onClick={() => onClose()}
                  >
                    닫기
                  </Button>
                  <Button
                    id="masterAccesBtn"
                    color="primary"
                    onClick={() => firstCallApi()}
                  >
                    등록
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default CashHandModal;
