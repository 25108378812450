import React from "react";
import { Row } from "reactstrap";

function Company({ handleChange, SelectFn, values, handleMoneyChange }) {
  const selOption = [
    {
      name: "직업",
      key: "jobname",
      type: "text",
    },
    {
      name: "직업 분류 ",
      key: "career",
      type: "radio",
      value: [
        "대기업",
        "중견기업",
        "중소기업",
        "스타트업",
        "공기업",
        "공공기관",
        "미용",
        "미디어",
        "음악",
        "레저/스포츠",
        "외식/식음료",
        "공인중개사",
        "문과전문직",
        "이과전문직",
        "간호사",
        "의료직",
        "유치원교사",
        "초등학교교사",
        "중,고등학교교사",
        "학원강사",
        "교육직",
        "공무원",
        "경찰",
        "소방관",
        "직업군인",
        "사업가",
        "자영업",
        "금융직",
        "프리랜서",
        "기타"
      ],
    },
    {
      name: "기타직업",
      key: "etcjobname",
      type: "text",
    },
    {
      name: "고용형태",
      key: "jobstatus",
      type: "radio",
      value: [
        "정규직",
        "계약직",
        "프리랜서",
        "가업승계",
        "개인사업자",
        "법인사업자",
      ],
    },
    {
      name: "근무형태",
      key: "workstatus",
      type: "radio",
      value: ["일반근무", "교대근무", "재택근무", "자율근무", "파트타임"],
    },
    {
      name: "연봉(세전)",
      key: "yearmoney",
      type: "money",
    },
    {
      name: "직장명",
      key: "comname",
      type: "text",
    },
    {
      name: "직급",
      key: "level",
      type: "text",
    },
    {
      name: "회사소재지",
      key: "jobaddres",
      type: "text",
    },
  ];
  return (
    <React.Fragment>
      <h3 className="MainText">직업</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      {selOption?.map((el, i) => {
        if (el.type === "text") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else if (el.type === "money") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            value: el.value,
            values,
            key: i,
            handleMoneyChange,
          });
        } else {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            handleChange,
          });
        }
      })}
    </React.Fragment>
  );
}

export default Company;
