import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const MessageModal = ({ Msg, setMsg }) => {
  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard py-3 ps-3 pe-2">
              <CardBody>
                <h2>메세지 내용</h2>
                <p className="card-title-desc"></p>
                <FormGroup className="mb-3">
                  <textarea
                    className="js-input-mask form-control customInput"
                    value={Msg}
                    rows={5}
                    readOnly
                    style={{ cursor: "default", resize: "none" }}
                  />
                </FormGroup>
                <Row className="w-100 m-0 mt-3">
                  <Col className="text-end">
                    <Button
                      id="masterAccesBtn"
                      color="primary"
                      onClick={() => setMsg(null)}
                    >
                      확인
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default MessageModal;
