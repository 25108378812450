import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";
import { adminUserPut,adminRequestPost, requestGet } from "../../apis/request";


// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PremiumMessageModal from "./Modal/PremiumMessageModal";

function PostLikeList({ id, resetAPI }) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [modalMsg, setModalMsg] = useState(null);

  const callCancel = async (id) => {
    console.log("call cancel id : " + id);
    if (window.confirm("호감을 취소 하시겠습니까?")) {
      try {
        let body = {
          id: id,
        };
        await adminUserPut(`/api/like/cancel`, body);
        alert("취소 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert(error.data.message);
      }
    }
  };

  const columns = [
    {
      dataField: "name",
      text: "이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.userId}`} style={{ color: "#495057" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "gender",
      text: "성별",
      sort: true,
    },
    {
      dataField: "read",
      text: "읽음 여부",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "일자",
      sort: true,
    },
    {
      dataField: "status",
      text: "상태",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <span
            onClick={() => {
              if (cellContent === "거절") {
                return setModalMsg(row.answer);
              }
            }}
          >
            {cellContent}
          </span>
        );
      },
    },
    {
      dataField: "type",
      text: "호감 종류",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <span
            onClick={() => {
              return setModalMsg(row.message);
            }}
          >
            {cellContent}
          </span>
        );
      },
    },
    
    {
      dataField: "cancelLike",
      text: "호감 취소",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        
        if (!row.read && row.status === "요청중") {
          return (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                callCancel(row.id);
              }}
            >
              호감 취소
            </button>
          );
        }
      },
    },
    // {
    //   dataField: "phone",
    //   text: "전화번호",
    //   sort: true,
    // },
    // {
    //   dataField: "recommender",
    //   text: "추천인",
    //   sort: true,
    // },
    // {
    //   dataField: "gender",
    //   text: "성별",
    //   sort: true,
    // },
    // {
    //   dataField: "birthday",
    //   text: "생년월일",
    //   sort: true,
    // },
    // {
    //   dataField: "grade",
    //   text: "등급",
    //   sort: true,
    // },
    // {
    //   dataField: "received",
    //   text: "받은호감",
    //   sort: true,
    // },
    // {
    //   dataField: "sent",
    //   text: "보낸호감",
    //   sort: true,
    // },
    // {
    //   dataField: "regDate",
    //   text: "가입일시",
    //   sort: true,
    // },
    // {
    //   dataField: "isSleep",
    //   text: "휴면여부",
    //   sort: true,
    // },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const callInfo = useCallback(async () => {
    const memberResponse = await requestGet(`/api/like/sent/list/${id}`);
    setMemberList(memberResponse?.data);
  }, [id]);

  useEffect(() => {
    if (id && resetAPI) {
      callInfo();
    }
  }, [callInfo, id, resetAPI]);

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          name: item.name,
          gender: item.gender,
          grade: item.grade,
          read: item.read,
          type:
            item.type === "premium"
              ? "프리미엄"
              : item.type === "normal"
              ? "일반"
              : "",
          userId: item.userId,
          message: item.message,
          createdAt: item.createdAt,
          answer: item.answer,
          status:
            item.status === "request"
              ? "요청중"
              : item.status === "accept"
              ? "수락"
              : item.status === "reject"
              ? "거절"
              : "",
        })
      );
      setProductData(arr);
    }
  }, [memberList]);

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">보낸 호감 목록</h3>
      <p className="card-title-desc"></p>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={columns}
        data={productData}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            columns={columns}
            data={productData}
            search
          >
            {(toolkitProps) => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={"id"}
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {modalMsg ? (
        <PremiumMessageModal Msg={modalMsg} setMsg={setModalMsg} />
      ) : null}
    </React.Fragment>
  );
}

export default PostLikeList;
