import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMatchListHistoryStore } from "../../store/zustand/state";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";

import { adminRequestPost, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import ConnectModal from "./Modal/ConnectModal";
import moment from "moment";
import ConnectEditModal from "./Modal/ConnectEditModal";

function MatchListHistory(props) {
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [connectModal, setConnectModal] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [MM, setMM] = useState("");
  const [place, setPlace] = useState("");
  const [etc, setEtc] = useState("");
  const [memo, setMemo] = useState("");
  const [connectID, setConnectID] = useState("");
  const [manager, setManager] = useState("");
  const [tableHeight, setTableHeight] = useState(0);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const { list, updateList, updateActiveNum } = useMatchListHistoryStore();

  const { page } = useParams();

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    // },
    {
      dataField: "senderName",
      text: "보낸사람 이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
            href={`/member/detail/${row.senderId}`}
            style={{ color: "#495057" }}
          >
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "senderManager",
      text: "보낸사람 담당 매니저",
      sort: true,
    },
    {
      dataField: "receiverName",
      text: "받은사람 이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
            href={`/member/detail/${row.receiverId}`}
            style={{ color: "#495057" }}
          >
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "receiverManager",
      text: "받은사람 담당 매니저",
      sort: true,
    },
    {
      dataField: "meetingTime",
      text: "만남 시간",
      sort: true,
    },
    {
      dataField: "etc",
      text: "기타",
      sort: true,
    },
    {
      dataField: "startTime",
      text: "연결 시작 시간",
      sort: true,
    },
    {
      dataField: "endTime",
      text: "연결 종료 시간",
      sort: true,
    },
    {
      dataField: "meetingPlace",
      text: "만남 장소",
      sort: true,
    },
    {
      dataField: "memo",
      text: "메모",
      sort: true,
    },
    {
      dataField: "senderGender",
      text: "보낸사람 성별",
      sort: true,
    },
    {
      dataField: "senderPhone",
      text: "보낸사람 전화번호",
      sort: true,
    },
    {
      dataField: "senderSafeNumber",
      text: "보낸사람 안심번호",
      sort: true,
    },
    {
      dataField: "receiverGender",
      text: "받은사람 성별",
      sort: true,
    },
    {
      dataField: "receiverPhone",
      text: "받은사람 전화번호",
      sort: true,
    },
    {
      dataField: "receiverSafeNumber",
      text: "받은사람 안심번호",
      sort: true,
    },
    {
      dataField: "status",
      text: "상태",
      sort: true,
    },
    {
      dataField: "manager",
      text: "연결 담당 매니저",
      sort: true,
    },
    {
      dataField: "sendDate",
      text: "전송 날짜",
      sort: true,
    },
    {
      dataField: "action",
      text: "연결 보내기",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => callConnect(row.id)}
            className="btn btn-primary btn-sm"
          >
            보내기
          </button>
        );
      },
    },
    {
      dataField: "action",
      text: "연결 수정",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => callEditConnect(row)}
            className="btn btn-warning btn-sm"
          >
            수정
          </button>
        );
      },
    },
    {
      dataField: "action",
      text: "연결 해제",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <button
            onClick={(e) => callDisconnect(row.id)}
            className="btn btn-danger btn-sm"
          >
            해제
          </button>
        );
      },
    },
  ];

  const callEditConnect = (row) => {
    setDate(moment(row.meetingTime).format("YYYY-MM-DD"));
    setTime(moment(row.meetingTime).format("HH"));
    setMM(moment(row.meetingTime).format("mm"));
    setPlace(row.meetingPlace);
    setEtc(row.etc);
    setMemo(row.memo);
    setConnectID(row.id);
    setManager(row.manager);
    setConnectModal(true);
  };

  const defaultSorted = [];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: editData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const callApi = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      const response = await requestGet(`/api/match/connect/list?${params}`);
      setData(response?.data);
      setLastpage(response?.totalPage);
      setTotalCount(response?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  useEffect(() => {
    setActiveNum(list.page);
    callApi();
  }, [callApi, list]);

  const gohistory = () => {
    updateList(activeNum);
  };

  useEffect(() => {
    if (data?.length > 0) {
      let arr = [];
      data.forEach((item) =>
        arr.push({
          createdAt: item.createdAt,
          endTime: item.endTime,
          etc: item.etc,
          id: item.id,
          meetingPlace: item.meetingPlace,
          meetingTime: item.meetingTime,
          memo: item.memo,
          receiverGender: item.receiverGender,
          receiverGrade: item.receiverGrade,
          receiverId: item.receiverId,
          receiverManager: item.receiverManager,
          receiverName: item.receiverName,
          receiverPhone: item.receiverPhone,
          receiverSafeNumber: item.receiverSafeNumber,
          senderGender: item.senderGender,
          senderGrade: item.senderGrade,
          senderId: item.senderId,
          senderManager: item.senderManager,
          senderName: item.senderName,
          senderPhone: item.senderPhone,
          senderSafeNumber: item.senderSafeNumber,
          startTime: item.startTime,
          status: item.status,
          manager: item.manager,
          sendDate: item.sendDate,
        })
      );
      setEditData(arr);
    }
  }, [data]);

  const callDisconnect = async (id) => {
    if (window.confirm("해제 하시겠습니까?"))
      try {
        let body = {
          id: id,
        };
        await adminRequestPost(`/api/match/disconnect`, body);
        alert("해제 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert(error?.data?.error);
      }
  };

  const callConnect = async (id) => {
    if (window.confirm("연결 하시겠습니까?"))
      try {
        let body = {
          id: id,
        };
        await adminRequestPost(`/api/match/connect/send`, body);
        alert("연결 성공 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert(error?.data?.error);
      }
  };

  useEffect(() => {
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, editData]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>매칭 연결 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={editData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>
                            매칭 연결 목록
                          </h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <div className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </div>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {connectModal ? (
        <ConnectEditModal
          connectID={connectID}
          setConnectID={setConnectID}
          setConnectModal={setConnectModal}
          date={date}
          setDate={setDate}
          time={time}
          setTime={setTime}
          MM={MM}
          setMM={setMM}
          place={place}
          setPlace={setPlace}
          etc={etc}
          setEtc={setEtc}
          memo={memo}
          setMemo={setMemo}
          manager={manager}
          setManager={setManager}
        />
      ) : null}
    </React.Fragment>
  );
}

export default MatchListHistory;
