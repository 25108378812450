import React, { useCallback, useEffect, useState } from "react";
import { useMileageHistoryListStore } from "../../store/zustand/state";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useParams } from "react-router-dom";

import { adminUserPut, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";

function MileageHistory({ id, callAPI, resetAPI }) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [likeNum, setLikeNum] = useState();
  const [desc, setDesc] = useState("");
  const { list, updateList, updateActiveNum } = useMileageHistoryListStore();

  const columns = [
    {
      dataField: "change",
      text: "사용량",
      sort: true,
    },
    {
      dataField: "before",
      text: "이전 보유량",
      sort: true,
    },
    {
      dataField: "after",
      text: "이후 보유량",
      sort: true,
    },
    {
      dataField: "because",
      text: "내용",
      sort: true,
    },
    {
      dataField: "comment",
      text: "사유",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "사용 시간",
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      params.append("userId", id);
      const memberResponse = await requestGet(
        `/api/cash/history/list/mileage?${params}`
      );
      setMemberList(memberResponse?.data);
      // setLastpage(memberResponse?.totalPage);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  useEffect(() => {
    if (id && resetAPI) {
      callInfo();
    }
  }, [callInfo, id, resetAPI]);

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          name: item.name,
          change: item.change,
          before: item.before,
          after: item.after,
          because: item.because,
          comment: item.comment,
          createdAt: item.createdAt,
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  const likePost = async () => {
    try {
      let num = Math.abs(Number(likeNum));
      let body = { userId: id, mileage: String(num), comment: desc };
      await adminUserPut("/api/member/mileage", body);
      alert("추가 성공 하였습니다.");
      setLikeNum();
      callAPI();
      callInfo();
    } catch (error) {
      console.log(error);
      alert("추가 실패 하였습니다.");
    }
  };

  const likeGet = async () => {
    try {
      let num = Math.abs(Number(likeNum));
      let body = { userId: id, mileage: "-" + num, comment: desc };
      await adminUserPut("/api/member/mileage", body);
      alert("회수 성공 하였습니다.");
      setLikeNum();
      callAPI();
      callInfo();
    } catch (error) {
      console.log(error);
      alert("회수 실패 하였습니다.");
    }
  };

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">마일리지 사용 목록</h3>
      <p className="card-title-desc"></p>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={columns}
        data={productData}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            columns={columns}
            data={productData}
            search
          >
            {(toolkitProps) => (
              <React.Fragment>
                <Row className="mb-4">
                  <Col lg="12">
                    <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                      <Col lg={12} className="d-flex p-0 flex-column">
                        <Row className="w-100 m-0">
                          <Col
                            lg={2}
                            className="d-flex align-items-center"
                            style={{
                              padding: "0.47rem 0px 0.75rem 0.47rem",
                              backgroundColor: "#ccc",
                              fontWeight: 700,
                              borderTop: "0.3px solid #fff",
                              borderBottom: "0.3px solid #fff",
                            }}
                          >
                            <span>마일리지</span>
                          </Col>
                          <Col
                            className="m-0 p-0"
                            style={{
                              borderBottom: "1px solid #ced4da",
                            }}
                          >
                            <div className="h-100">
                              <input
                                type="number"
                                className="js-input-mask form-control customSearchInput h-100"
                                name="name"
                                id="nameLabel"
                                onWheel={(e) => e.target.blur()}
                                value={likeNum}
                                onChange={(e) => setLikeNum(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="w-100 m-0">
                          <Col
                            lg={2}
                            className="d-flex align-items-center"
                            style={{
                              padding: "0.47rem 0px 0.75rem 0.47rem",
                              backgroundColor: "#ccc",
                              fontWeight: 700,
                              borderTop: "0.3px solid #fff",
                              borderBottom: "0.3px solid #fff",
                            }}
                          >
                            <span>사유</span>
                          </Col>
                          <Col
                            className="m-0 p-0"
                            style={{
                              borderBottom: "1px solid #ced4da",
                            }}
                          >
                            <div className="h-100">
                              <input
                                type="text"
                                className="js-input-mask form-control customSearchInput h-100"
                                name="name"
                                id="nameLabel"
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={6} className="d-flex p-0 flex-column">
                        <div>
                          <Button
                            className="w-100"
                            color="primary"
                            style={{ borderRadius: "0px" }}
                            onClick={() => likePost()}
                          >
                            추가하기
                          </Button>
                        </div>
                      </Col>
                      <Col lg={6} className="d-flex p-0 flex-column">
                        <div>
                          <Button
                            className="w-100"
                            color="danger"
                            style={{ borderRadius: "0px" }}
                            onClick={() => likeGet()}
                          >
                            회수하기
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={"id"}
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </React.Fragment>
  );
}

export default MileageHistory;
