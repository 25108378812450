import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Row } from "reactstrap";
import "react-rangeslider/lib/index.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import "./schedule.scss";
import { adminRequestPost, requestGet } from "../../apis/request";
import moment from "moment";
// import "./consult.scss";

registerLocale("ko", ko);

function DisabledSchedule(props) {
  const [busyDates, setBusyDates] = useState([]);
  const [busyDate, setBusyDate] = useState([]);

  const isDateSelected = (date) =>
    busyDate.some(
      (selectedDate) =>
        date.getDate() === selectedDate.getDate() &&
        date.getMonth() === selectedDate.getMonth() &&
        date.getFullYear() === selectedDate.getFullYear()
    );

  const handleDateClick = (date) => {
    if (isDateSelected(date)) {
      submitRemoveDate(moment(date).format("YYYY-MM-DD"));
    } else {
      submitDate(moment(date).format("YYYY-MM-DD"));
    }
  };
  const callBusyDate = async () => {
    const response = await requestGet(`/api/counseling/date/busy`);
    setBusyDates(response?.data?.dates);
  };

  const submitDate = async (startDate) => {
    if (window.confirm(`${startDate}일 등록 하시겠습니까?`)) {
      try {
        let body = {
          date: moment(startDate).format("YYYY-MM-DD"),
          busyDate: true,
        };
        await adminRequestPost(`/api/counseling/date`, body);
        alert("등록 성공 하였습니다.");
        // window.location.reload();
        callBusyDate();
      } catch (error) {
        console.log(error);
        alert("등록 실패 하였습니다.");
      }
    }
  };

  const submitRemoveDate = async (date) => {
    if (window.confirm(`${date}일 해제 하시겠습니까?`)) {
      try {
        let body = {
          date: moment(date).format("YYYY-MM-DD"),
          busyDate: false,
        };
        await adminRequestPost(`/api/counseling/date`, body);
        alert("해제 성공 하였습니다.");
        // window.location.reload();
        callBusyDate();
      } catch (error) {
        console.log(error);
        alert("해제 실패 하였습니다.");
      }
    }
  };

  useEffect(() => {
    callBusyDate();
  }, []);

  useEffect(() => {
    let arr = [];
    busyDates.forEach((item) => arr.push(new Date(item)));
    setBusyDate(arr);
  }, [busyDates]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>불가능 스케쥴 관리</title>
        </MetaTags>
        <div className="container">
          <Card>
            <CardBody>
              <div className="d-flex flex-column mainBottomMargin">
                <h3 className="MainText">불가능 스케쥴 관리</h3>
              </div>
              <div className="row">
                <div id="formContainer" className="col-lg-12">
                  <div id="uploadResumeStepFormContent">
                    <Row>
                      <label for="firstDateLabel" className="labelText">
                        날짜
                      </label>
                      <Col lg={8} className="d-flex flex-column">
                        <DatePicker
                          minDate={new Date()}
                          onChange={handleDateClick}
                          highlightDates={busyDate}
                          inline
                          locale="ko"
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DisabledSchedule;
