import React from "react";
import { Row } from "reactstrap";

function UserInfo({ values, handleChange, SelectFn }) {
  const selOption = [
    {
      name: "이름",
      key: "name",
      type: "text",
    },
    {
      name: "성별",
      key: "gender",
      type: "radio",
      value: ["남성", "여성"],
    },
    {
      name: "생년월일",
      key: "birth",
      type: "date",
    },
    {
      name: "MBTI",
      key: "mbti",
      type: "text",
    },
    {
      name: "키",
      key: "height",
      type: "number",
      description:
        "*솔직한 키 작성을 부탁드립니다. 거짓된 키 작성 시 환불 없이 영구적으로 이용이 제한될 수 있습니다.",
    },
    {
      name: "체중",
      key: "weight",
      type: "number",
    },
    {
      name: "결혼여부",
      key: "isMarry",
      type: "radio",
      value: ["미혼", "사실혼", "무출산 재혼", "출산 재혼"],
    },
    {
      name: "거주지역",
      key: "customAdress",
      type: "location",
    },
    {
      name: "본가",
      key: "tureHome",
      type: "location",
    },
    {
      name: "주량",
      key: "drink",
      type: "radio",
      value: [
        "안마셔요",
        "어쩔 수 없을 때만 마셔요",
        "가끔마셔요",
        "어느정도 즐겨요",
        "즐기는 편이예요",
      ],
    },
    {
      name: "흡연",
      key: "smoke",
      type: "radio",
      value: ["비흡연", "흡연(연초)", "흡연(전자담배)", "금연예정"],
    },
    {
      name: "혈액형",
      key: "bloodType",
      type: "radio",
      value: ["A", "B", "AB", "O"],
    },
    {
      name: "문신",
      key: "tatoo",
      type: "radio",
      value: ["없음", "작은거1개", "작은거2개 또는 큰문신 1개이상"],
    },
    {
      name: "운동",
      key: "sport",
      type: "radio",
      value: ["안함", "주1~2회", "주3~4회", "매일"],
    },
    {
      name: "종교",
      key: "gido",
      type: "radio",
      value: ["무교", "천주교", "불교", "기독교", "기타"],
    },
    {
      name: "신체 특징",
      key: "bodyCharacter",
      type: "text",
    },
    {
      name: "병역여부",
      key: "army",
      type: "radio",
      value: ["예비역", "면제"],
    },
    {
      name: "본인 외모점수",
      key: "selfAppearance",
      type: "radio",
      value: [
        "못생김",
        "평균이하",
        "약간 평균 이하",
        "평범함",
        "호감형",
        "약간 매력적",
        "매력적",
        "매우 매력적",
      ],
    },
  ];

  return (
    <React.Fragment>
      <h3 className="MainText">기본 정보</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom" />
      {selOption?.map((el, i) => {
        if (el.type === "text") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else if (el.type === "number") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            description: el.description,
            handleChange,
          });
        } else if (el.type === "select") {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            values,
            handleChange,
          });
        } else if (el.type === "date") {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            values,
            handleChange,
          });
        } else {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            handleChange,
          });
        }
      })}
    </React.Fragment>
  );
}

export default UserInfo;
