import React from "react";
import { Row } from "reactstrap";

function HopeHate({ handleChange, SelectFn, values, handleCheckstr }) {
  const selOption = [
    {
      name: "직업분류",
      key: "hateIndustry",
      type: "text",
    },
    {
      name: "결혼여부",
      key: "hateMarriageHistory",
      type: "radio",
      value: ["미혼", "사실혼", "무출산 재혼", "출산 재혼"],
    },
    {
      name: "흡연",
      key: "hateSmokeType",
      type: "radio",
      value: ["비흡연", "흡연(연초)", "흡연(전자담배)", "금연예정", "기타"],
    },
    {
      name: "문신여부",
      key: "hateTatoo",
      type: "radio",
      value: [
        "상관없음",
        "작은거 1개",
        "작은거 2개 또는 큰문신 1개이상",
        "없음",
        "기타",
      ],
    },
    {
      name: "부모님 정보",
      key: "hateParentStatus",
      type: "radio",
      value: ["상관없음", "일반가정", "이혼함", "재혼함", "편부모", "양친작고"],
    },
    {
      name: "차량유무",
      key: "hateHasCarType",
      type: "radio",
      value: ["상관없음", "차량없음", "차량있음", "기타"],
    },
    {
      name: "고용형태",
      key: "hateEmploymentType",
      type: "checks",
      value: [
        "상관없음",
        "프리랜서",
        "법인사업자",
        "개인사업자",
        "가업승계",
        "정규직",
        "위촉직",
        "계약직",
      ],
    },
    {
      name: "관심사",
      key: "hateInterest",
      type: "text",
    },
  ];
  return (
    <React.Fragment>
      <h3 className="MainText">이상형 기피사항</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      {selOption?.map((el, i) => {
        if (el.type === "text") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            handleChange,
          });
        }
      })}
    </React.Fragment>
  );
}

export default HopeHate;
