import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "./modal.scss";
import {
  adminRequestPost,
  adminUserPut,
  requestGet,
} from "../../../apis/request";

function CashModal({
  setModal,
  productData,
  setModalType,
  modalType,
  userId,
  setDataId,
  dataId,
  callInfo,
  callAPI,
}) {
  const [value, setValue] = useState("");
  const [directType, setDirectType] = useState("");
  const [directCardCompany, setDirectCardCompany] = useState("");
  const [directCardMonthlyPayment, setDirectCardMonthlyPayment] = useState("");
  const [directPaymentNumber, setDirectPaymentNumber] = useState("");
  const [memo, setMemo] = useState("");
  const [productName, setProductName] = useState("");
  const [noHand, setNoHand] = useState(false);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const callFirstApi = async () => {
    if (modalType === "read") {
      try {
        const response = await requestGet(`/api/purchase/detail/${dataId}`);
        setValue(response?.data?.productId);
        setDirectType(response?.data?.directType);
        setDirectCardCompany(response?.data?.directCardCompany);
        setDirectCardMonthlyPayment(response?.data?.directCardMonthlyPayment);
        setDirectPaymentNumber(response?.data?.directPaymentNumber);
        setMemo(response?.data?.memo);
        setProductName(response?.data?.productName);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (modalType === "read") {
      callFirstApi();
    }
  }, []);

  const callApi = async () => {
    let price = "";
    productData.forEach((item) => {
      if (String(item.id) === String(value)) {
        price = item.price;
      }
    });
    if (modalType === "add") {
      try {
        let body = {
          userId: userId,
          productId: value,
          memo: memo,
          directType: directType,
          directPrice: price,
          directCardCompany: directCardCompany,
          directCardMonthlyPayment: Number(directCardMonthlyPayment),
          directPaymentNumber: directPaymentNumber,
          status: "complete",
        };
        await adminRequestPost("/api/purchase/direct", body);
        callInfo();
        alert("생성 완료 되었습니다.");
        setModalType("");
        if (callAPI) {
          callAPI();
        }
        setModal(false);
      } catch (error) {
        console.log(error);
        alert("생성 실패 하였습니다.");
        setModalType("");
        setModal(false);
      }
    } else if (modalType === "read") {
      try {
        let body = {
          id: dataId,
          userId: userId,
          productId: value,
          memo: memo,
          directType: directType,
          directPrice: price,
          directCardCompany: directCardCompany,
          directCardMonthlyPayment: Number(directCardMonthlyPayment),
          directPaymentNumber: directPaymentNumber,
        };
        await adminUserPut("/api/purchase/update", body);
        callInfo();
        alert("수정 완료 되었습니다.");
        setModalType("");
        setDataId("");
        if (callAPI) {
          callAPI();
        }
        setModal(false);
      } catch (error) {
        console.log(error);
        alert("수정 실패 하였습니다.");
        setModalType("");
        setDataId("");
        setModal(false);
      }
    }
  };

  const onClose = async () => {
    if (modalType === "add") {
      setModalType("");
      setModal(false);
    } else if (modalType === "read") {
      setModalType("");
      setDataId("");
      setModal(false);
    }
  };

  const deletePurchase = async () => {
    if (window.confirm("정말 삭제 하시겠습니까?")) {
      try {
        let body = {
          purchaseId: dataId,
        };
        await adminUserPut(`/api/purchase/direct/cancel`, body);
        callInfo();
        alert("삭제 되었습니다.");
        setModalType("");
        setDataId("");
        if (callAPI) {
          callAPI();
        }
        setModal(false);
      } catch (error) {
        console.log(error);
        if (error?.data?.message) {
          alert(error.data.message);
        }
      }
    }
  };

  useEffect(() => {
    productData.forEach((item) => {
      if (item.id === value) {
        setNoHand(true);
      }
    });
  }, [productData, value]);

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card className="modalCard py-3 ps-3 pe-2">
              <h2>결제 조회</h2>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      상품
                    </label>
                    {noHand || modalType === "add" ? (
                      <select
                        id="counselor_genderLabel"
                        className="form-select customInput"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      >
                        {value === "" ? (
                          <option value="">선택해주세요.</option>
                        ) : null}
                        {productData?.map((item, i) => {
                          return (
                            <option value={item.id} key={i}>
                              {`상품 이름 : ${item.name},
                    가격 : ${
                      String(item.price).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      "원"
                    }`}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        value={productName}
                        disabled
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText">결제 타입</label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select customInput"
                      value={directType}
                      onChange={(e) => setDirectType(e.target.value)}
                    >
                      {!directType ? (
                        <option value="">선택해주세요.</option>
                      ) : null}
                      <option value="card">카드</option>
                      <option value="bank">계좌이체</option>
                    </select>
                  </div>
                </Col>
              </Row>
              {directType === "card" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        카드사
                      </label>
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="카드사를 입력해 주세요."
                        aria-label="카드사를 입력해 주세요."
                        value={directCardCompany}
                        onChange={(e) => setDirectCardCompany(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {directType === "card" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        할부
                      </label>
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="할부를 입력해 주세요."
                        aria-label="할부를 입력해 주세요."
                        value={directCardMonthlyPayment}
                        onChange={(e) =>
                          setDirectCardMonthlyPayment(e.target.value)
                        }
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              {directType === "card" ? (
                <Row className="rowBorderBottom pt-3 pb-3">
                  <Col lg={12} className="d-flex flex-column">
                    <div>
                      <label htmlFor={`statusLabel`} className="labelText">
                        카드번호
                      </label>
                      <input
                        type="text"
                        className="js-input-mask form-control customInput"
                        name="name"
                        id="nameLabel"
                        placeholder="카드번호를 입력해 주세요."
                        aria-label="카드번호를 입력해 주세요."
                        value={directPaymentNumber}
                        onChange={(e) => setDirectPaymentNumber(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              <Row className="rowBorderBottom pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label className="labelText">메모</label>
                    <textarea
                      className="js-input-mask form-control customInput"
                      placeholder="메모를 입력해 주세요."
                      aria-label="메모를 입력해 주세요."
                      rows={5}
                      value={memo}
                      style={{ resize: "none" }}
                      onChange={(e) => setMemo(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0 mt-3">
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    color="danger"
                    style={{ marginRight: "10px" }}
                    onClick={() => onClose()}
                  >
                    닫기
                  </Button>
                  <Button
                    id="masterAccesBtn"
                    color="primary"
                    onClick={() => callApi()}
                    style={modalType !== "add" ? { marginRight: "10px" } : null}
                  >
                    {modalType === "add" ? "생성" : "수정"}
                  </Button>
                  {modalType !== "add" ? (
                    <Button
                      id="masterAccesBtn"
                      color="dark"
                      onClick={() => deletePurchase()}
                    >
                      삭제
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default CashModal;
