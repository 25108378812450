import React from "react";
import { Row } from "reactstrap";

function Family({ handleChange, SelectFn, values, handleMoneyChange }) {
  const selOption = [
    {
      name: "가족형태",
      key: "familyinfo",
      type: "radio",
      value: ["일반가정", "재혼가정", "다문화가정", "장애인가정"],
    },
    {
      name: "형제관계",
      key: "brothers",
      type: "brothers",
    },
    {
      name: "아버지 직업",
      key: "fajob",
      type: "text",
    },
    {
      name: "어머니 직업",
      key: "mojob",
      type: "text",
    },
    {
      name: "부모님자산",
      key: "pamoney",
      type: "money",
    },
    {
      name: "부모님과의 동거 여부",
      key: "livealone",
      type: "radio",
      value: ["동거중", "동거안함"],
    },
    {
      name: "부모님의 동거 여부",
      key: "pawithlive",
      type: "radio",
      value: ["동거중", "동거안함"],
    },
    {
      name: "노후 준비 여부",
      key: "paalone",
      type: "radio",
      value: ["가능", "불가"],
    },
    {
      name: "부모님 이혼여부",
      key: "paend",
      type: "radio",
      value: ["해당함", "해당없음"],
    },
    {
      name: "부모님 작고여부",
      key: "pasad",
      type: "radio",
      value: ["양친생존", "편부모", "양친작고"],
    },
  ];
  return (
    <React.Fragment>
      <h3 className="MainText">가족관계</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      {selOption?.map((el, i) => {
        if (el.type === "text") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else if (el.type === "brothers") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else if (el.type === "money") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            value: el.value,
            values,
            handleMoneyChange,
          });
        } else {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            handleChange,
          });
        }
      })}
    </React.Fragment>
  );
}

export default Family;
