import React, { useCallback, useEffect, useState } from "react";
import { useLikeHistoryListStore } from "../../store/zustand/state";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useParams } from "react-router-dom";

import { adminUserPut, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
//lch1721 매칭권 사용 목록 탭
function LikeHistory({ id, callAPI, likeTotal, likeFreeTotal, resetAPI }) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [likeNum, setLikeNum] = useState();
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(1);
  const [lastpage, setLastpage] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [like, setLike] = useState("유료 매칭권");
  const { list, updateList, updateActiveNum } = useLikeHistoryListStore();

  const columns = [
    {
      dataField: "change",
      text: "사용량",
      sort: true,
    },
    {
      dataField: "type",
      text: "타입",
      sort: true,
    },
    {
      dataField: "because",
      text: "사유",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "사용 시간",
      sort: true,
    },
    {
      dataField: "action",
      text: "변경",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="d-flex">
            <select
              className="form-select customInput w-auto"
              name="joinFrom"
              value={row.types}
              onChange={(e) => updateTypeById(row.id, e.target.value)}
            >
              <option value={"무료"}>무료</option>
              <option value={"유료"}>유료</option>
              <option value={"얼리버드"}>얼리버드</option>
            </select>
            <Button
              color="primary"
              className="ms-2"
              onClick={() => updateType(row.id, row.types)}
            >
              변경
            </Button>
          </div>
        );
      },
    },
  ];

  const updateTypeById = (id, newType) => {
    setProductData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, types: newType } : item
      )
    );
  };

  const updateType = async (id, types) => {
    if (window.confirm("타입을 변경하시겠습니까?")) {
      try {
        let body = {
          id: id,
          type:
            types === "유료"
              ? "finalLikeCoupon"
              : types === "무료"
              ? "finalFreeLikeCoupon"
              : types === "얼리버드"
              ? "earlybirdLikeCoupon"
              : "",
        };
        await adminUserPut(`/api/cash/history/change/type`, body);
        alert("변경 완료 하였습니다.");
        callAPI();
        callInfo();
      } catch (error) {
        console.log(error);
        alert(error.data.message);
      }
    }
  };

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      params.append("userId", id);
      const memberResponse = await requestGet(
        `/api/cash/history/list/like/final?${params}`
      );
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  useEffect(() => {
    setActiveNum(list.page);
    if (id && resetAPI) {
      callInfo();
    }
  }, [callInfo, id, resetAPI, list]);

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          name: item.name,
          change: item.change,
          type:
            item.type === "likeCoupon" || item.type === "finalLikeCoupon"
              ? "유료"
              : item.type === "finalFreeLikeCoupon" ||
                item.type === "freeLikeCoupon"
              ? "무료"
              : item.type === "earlybirdLikeCoupon"
              ? "얼리버드"
              : item.type === "subscribe"
              ? "기간제"
              : "",
          types:
            item.type === "likeCoupon" || item.type === "finalLikeCoupon"
              ? "유료"
              : item.type === "finalFreeLikeCoupon" ||
                item.type === "freeLikeCoupon"
              ? "무료"
              : item.type === "earlybirdLikeCoupon"
              ? "얼리버드"
              : item.type === "subscribe"
              ? "기간제"
              : "",
          because: item.because,
          createdAt: item.createdAt,
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  const likePost = async () => {
    if(likeNum){
      let inputValue = likeNum.trim();  // trim()으로 양쪽 공백 제거
      if (inputValue === "") {
        return;
      } else if (!/^\d+$/.test(inputValue)) {
        return;
      } else {
        let numericValue = Math.abs(Number(likeNum));
        if (numericValue > 0) {
          try {
            let checkMessage = "";
            if (like === "유료 매칭권") {
              checkMessage = "유료 매칭권";
            } else if (like === "무료 매칭권") {
              checkMessage = "무료 매칭권";
            }
            if (window.confirm(checkMessage + " " + String(numericValue) + "장을 지급하시겠습니까?")) {
              let body = { userId: id, coupon: String(numericValue) };
              if (like === "유료 매칭권") {
                await adminUserPut("/api/member/likecoupon", body);
              } else if (like === "무료 매칭권") {
                await adminUserPut("/api/member/likecoupon/free", body);
              }
              alert("추가 성공 하였습니다.");
              callAPI();
              callInfo();
            }
            
            
          } catch (error) {
            console.log(error);
            alert("추가 실패 하였습니다.");
          }
        }
      }
    }
    


    
  };

  const likeGet = async () => {
    if(likeNum){
      let inputValue = likeNum.trim();  // trim()으로 양쪽 공백 제거
      if (inputValue === "") {
        return;
      } else if (!/^\d+$/.test(inputValue)) {
        return;
      } else {
        let numericValue = Math.abs(Number(likeNum));
        if (numericValue > 0) {
          try {
            let checkMessage = "";
            if (like === "유료 매칭권") {
              checkMessage = "유료 매칭권";
            } else if (like === "무료 매칭권") {
              checkMessage = "무료 매칭권";
            }
            if (window.confirm(checkMessage + " " + String(numericValue) + "장을 회수하시겠습니까?")) {
              let body = { userId: id, coupon: "-" + numericValue };
              if (like === "유료 매칭권") {
                await adminUserPut("/api/member/likecoupon", body);
              } else if (like === "무료 매칭권") {
                await adminUserPut("/api/member/likecoupon/free", body);
              }
              alert("추가 성공 하였습니다.");
              callAPI();
              callInfo();
            }
            
            
          } catch (error) {
            console.log(error);
            alert("추가 실패 하였습니다.");
          }
        }
      }
    }
    
  };

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">매칭권 사용 목록</h3>
      <p className="card-title-desc"></p>
      <ToolkitProvider
        keyField="id"
        columns={columns}
        data={productData}
        search
      >
        {(toolkitProps) => (
          <React.Fragment>
            <Row className="mb-4">
              <Col lg="12">
                <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                  <Col lg={12} className="d-flex p-0 flex-column">
                    <Row className="w-100 m-0">
                      <Col
                        lg={2}
                        className="d-flex align-items-center"
                        style={{
                          padding: "0px 0px 0px 0.47rem",
                          backgroundColor: "#ccc",
                          fontWeight: 700,
                        }}
                      >
                        <select
                          className="form-select"
                          style={{
                            border: "none",
                            backgroundColor: "#ccc",
                          }}
                          value={like}
                          onChange={(e) => setLike(e.target.value)}
                        >
                          <option value="유료 매칭권">유료 매칭권</option>
                          <option value="무료 매칭권">무료 매칭권</option>
                        </select>
                      </Col>
                      <Col
                        className="m-0 p-0"
                        style={{
                          borderBottom: "1px solid #ced4da",
                        }}
                      >
                        <div className="h-100">
                          <input
                            type="number"
                            className="js-input-mask form-control customSearchInput h-100"
                            name="name"
                            id="nameLabel"
                            onWheel={(e) => e.target.blur()}
                            value={likeNum}
                            onChange={(e) => setLikeNum(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} className="d-flex p-0 flex-column">
                    <div>
                      <Button
                        className="w-100"
                        color="primary"
                        style={{ borderRadius: "0px" }}
                        onClick={() => likePost()}
                      >
                        추가하기
                      </Button>
                    </div>
                  </Col>
                  <Col lg={6} className="d-flex p-0 flex-column">
                    <div>
                      <Button
                        className="w-100"
                        color="danger"
                        style={{ borderRadius: "0px" }}
                        onClick={() => likeGet()}
                      >
                        회수하기
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <div className="table-responsive">
                  <div className="d-flex justify-content-between customTotalcount">
                    <span>
                      유료 총 보유량:{likeTotal} , 무료 총 보유량:
                      {likeFreeTotal}
                    </span>
                    <span>
                      검색결과 (
                      {String(totalCount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      개의 검색결과가 있습니다)
                    </span>
                  </div>
                  <BootstrapTable
                    keyField={"id"}
                    responsive
                    bordered={false}
                    striped={false}
                    // selectRow={selectRow}
                    classes={"table align-middle table-nowrap"}
                    headerWrapperClasses={"thead-light"}
                    {...toolkitProps.baseProps}
                  />
                </div>
              </Col>
            </Row>{" "}
            {!lastpage ? null : (
              <Row className="w-100 mx-0 my-3 p-0" style={{ overflow: "auto" }}>
                <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                  <PaginationAPIWrap
                    pageNumber={pageNumber}
                    setpageNumber={setpageNumber}
                    lastpage={lastpage}
                    activeNum={activeNum}
                    setActiveNum={updateActiveNum}
                  />
                </Col>
                <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                  <MobilePaginationAPIWrap
                    pageNumber={mobilepageNumber}
                    setpageNumber={setMobilepageNumber}
                    lastpage={lastpage}
                    activeNum={activeNum}
                    setActiveNum={updateActiveNum}
                  />
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
      </ToolkitProvider>
    </React.Fragment>
  );
}

export default LikeHistory;
