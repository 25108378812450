import React, { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import moment from "moment";
import { ReactComponent as Calendar } from "../../assets/icon/svgs/regular/calendar.svg";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import ReactSelect from "react-select";
import { adminRequestPost, requestGet } from "../../apis/request";

registerLocale("ko", ko);

function ConsultRequest(props) {
  const [face, setFace] = useState(0);
  const [job, setJob] = useState(0);
  const [family, setFamily] = useState(0);
  const [userGender, setUserGender] = useState(null);
  const [managerGender, setManagerGender] = useState(null);
  const [education, setEducation] = useState(null);
  const [location, setLocation] = useState(null);
  const [year, setYear] = useState(null);
  const [privacy_agree, setPrivacy_agree] = useState(false);
  const [marketing_agree, setMarketing_agree] = useState(false);
  const [educationList, setEducationList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [firstDate, setFirstDate] = useState();
  const [secondDate, setSecondDate] = useState();
  const datePickerRef = useRef(null);
  const datePickerRef2 = useRef(null);
  const history = useHistory();

  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };
  const openDatePicker2 = () => {
    datePickerRef2.current.setOpen(true);
  };

  const callInfo = async () => {
    let eduarr = [];
    let locarr = [];
    let yeararr = [];
    const educationResponse = await requestGet(`/api/education/list`);
    const locationResponse = await requestGet(`/api/location/list`);
    const yearResponse = await requestGet(`/api/list/year`);
    educationResponse?.data?.forEach((item) =>
      eduarr.push({ label: item.name, value: item.id })
    );
    locationResponse?.data?.forEach((item) =>
      locarr.push({ label: item.name, value: item.id })
    );
    yearResponse?.forEach((item) => yeararr.push({ label: item, value: item }));
    setEducationList(eduarr);
    setLocationList(locarr);
    setYearList(yeararr);
  };

  useEffect(() => {
    callInfo();
  }, []);

  const optionGroup = [
    { label: "남자", value: "남자" },
    { label: "여자", value: "여자" },
  ];

  function handleUserGender(userGender) {
    setUserGender(userGender);
  }

  function handleManagerGender(managerGender) {
    setManagerGender(managerGender);
  }

  function handleEducation(education) {
    setEducation(education);
  }

  function handleLocation(location) {
    setLocation(location);
  }

  function handleYear(year) {
    setYear(year);
  }

  const submitApi = async (event, values) => {
    try {
      if (
        location !== null &&
        values.name &&
        userGender !== null &&
        values.phone &&
        firstDate &&
        secondDate &&
        education !== null &&
        managerGender !== null &&
        year !== null
      ) {
        let body = {
          location: location.value,
          counselingDates: [
            moment(firstDate).format("YYYY-MM-DD HH:mm:ss"),
            moment(secondDate).format("YYYY-MM-DD HH:mm:ss"),
          ],
          name: values.name,
          gender: userGender.value,
          phone: values.phone,
          education: education.value,
          ideal_appearance: face,
          ideal_ability: job,
          ideal_family: family,
          counselor_gender: managerGender.value,
          birth_year: year.value,
          marketing_agree: marketing_agree,
          privacy_agree: privacy_agree,
        };
        await adminRequestPost(`/api/counseling/request`, body);
        alert("신청 성공 하였습니다.");
        window.location.href = "/consult/list";
      } else {
        alert("전부 기입해 주세요.");
      }
    } catch (error) {
      alert("다시 시도해 주세요.");
    }
  };

  const minTime = new Date();
  minTime.setHours(9);
  minTime.setMinutes(0);

  const maxTime = new Date();
  maxTime.setHours(18);
  maxTime.setMinutes(0);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>상담 신청</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Consult" breadcrumbItem="상담 신청" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <h2>상담 신청</h2>
                  <p className="card-title-desc"></p>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      submitApi(e, v);
                    }}
                  >
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label">지사</Label>
                          <ReactSelect
                            value={location}
                            onChange={handleLocation}
                            options={locationList}
                            placeholder="지사를 선택해 주세요."
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col lg={4} className="d-flex flex-column">
                        <Label for="example-date-input" className="form-label">
                          1순위 상담 일정
                        </Label>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <DatePicker
                            ref={datePickerRef}
                            className="form-control"
                            selected={firstDate}
                            onChange={(date) => setFirstDate(date)}
                            placeholderText="YYYY. MM. dd. HH:00"
                            showTimeSelect
                            minTime={minTime}
                            maxTime={maxTime}
                            timeFormat="HH:00"
                            timeIntervals={60}
                            timeCaption="시간"
                            dateFormat="yyyy. MM. dd. HH:00"
                            locale="ko"
                          />
                          <Calendar
                            onClick={openDatePicker}
                            width={13}
                            height={13}
                            style={{
                              position: "absolute",
                              right: "15px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg={4} className="d-flex flex-column">
                        <Label for="example-date-input" className="form-label">
                          2순위 상담 일정
                        </Label>
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <DatePicker
                            ref={datePickerRef2}
                            className="form-control"
                            selected={secondDate}
                            onChange={(date) => setSecondDate(date)}
                            placeholderText="YYYY. MM. dd. HH:00"
                            showTimeSelect
                            minTime={minTime}
                            maxTime={maxTime}
                            timeFormat="HH:00"
                            timeIntervals={60}
                            timeCaption="시간"
                            dateFormat="yyyy. MM. dd. HH:00"
                            locale="ko"
                          />
                          <Calendar
                            onClick={openDatePicker2}
                            width={13}
                            height={13}
                            style={{
                              position: "absolute",
                              right: "15px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom03">이름</Label>
                          <AvField
                            name="name"
                            placeholder="이름을 입력해 주세요."
                            type="text"
                            errorMessage=" 필수 입력 입니다."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom04">출생년도</Label>
                          <ReactSelect
                            value={year}
                            onChange={handleYear}
                            options={yearList}
                            placeholder="지사를 선택해 주세요."
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom05">성별</Label>
                          <ReactSelect
                            value={userGender}
                            onChange={handleUserGender}
                            options={optionGroup}
                            placeholder="성별을 선택해 주세요."
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom03">최종학력</Label>
                          <ReactSelect
                            value={education}
                            onChange={handleEducation}
                            options={educationList}
                            placeholder="최종학력을 선택해 주세요."
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom04">연락처</Label>
                          <AvField
                            name="phone"
                            placeholder="연락처를 입력해 주세요."
                            type="text"
                            errorMessage=" 필수 입력 입니다."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom05">
                            희망상담사 성별
                          </Label>
                          <ReactSelect
                            value={managerGender}
                            onChange={handleManagerGender}
                            options={optionGroup}
                            placeholder="성별을 선택해 주세요."
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h5>
                          이성 선호도(외모/능력/집안 총점 10점으로 중요도 점수)
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom03">외모</Label>
                          <Col>
                            <div className="p-3">
                              <span className="float-start mt-5">0</span>{" "}
                              <span className="float-end  mt-5">10</span>
                              <Slider
                                value={face}
                                min={0}
                                max={10}
                                orientation="horizontal"
                                onChange={(value) => {
                                  setFace(value);
                                }}
                              />
                            </div>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom04">능력&집안</Label>
                          <Col>
                            <div className="p-3">
                              <span className="float-start mt-5">0</span>{" "}
                              <span className="float-end  mt-5">10</span>
                              <Slider
                                value={job}
                                min={0}
                                max={10}
                                orientation="horizontal"
                                onChange={(value) => {
                                  setJob(value);
                                }}
                              />
                            </div>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom05">성격</Label>
                          <Col>
                            <div className="p-3">
                              <span className="float-start mt-5">0</span>{" "}
                              <span className="float-end  mt-5">10</span>
                              <Slider
                                value={family}
                                min={0}
                                max={10}
                                orientation="horizontal"
                                onChange={(value) => {
                                  setFamily(value);
                                }}
                              />
                            </div>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="invalidCheck"
                              checked={privacy_agree}
                              onClick={() => setPrivacy_agree(!privacy_agree)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="invalidCheck"
                            >
                              {" "}
                              개인정보 수집동의
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="invalidCheck1"
                              checked={marketing_agree}
                              onClick={() =>
                                setMarketing_agree(!marketing_agree)
                              }
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="invalidCheck1"
                            >
                              {" "}
                              마케팅 활용 동의
                            </Label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      신청하기
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ConsultRequest;
