import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

function Profile({ editData }) {
  return (
    <React.Fragment>
      <h2>프로필</h2>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom03">신장</Label>
            <div>{editData?.address?.height}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom05">체중</Label>
            <div>{editData?.address?.weight}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">혈액형</Label>
            <div>??</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">종교</Label>
            <div>??</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">건강상태</Label>
            <div>??</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">흡연</Label>
            <div>??</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">음주</Label>
            <div>??</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">MBTI</Label>
            <div>??</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">나의성격 성향</Label>
            <div>
              {editData?.character?.my?.map((item, i) => {
                return <span key={i}>{item}, </span>;
              })}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">이성의 성격 성향</Label>
            {editData?.character?.appealing?.map((item, i) => {
              return <span key={i}>{item}, </span>;
            })}
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">취미</Label>
            <div>{editData?.hobby}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">특기</Label>
            <div>??</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">자격면허</Label>
            <div>{editData?.licenses}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">병역정보</Label>
            <div>
              {editData?.military === 0
                ? "미필"
                : editData?.military === 1
                ? "군필"
                : "면제"}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">성혼비</Label>
            <div>{editData?.marriageFee}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">본인 소개</Label>
            <div>{editData?.selfIntro}</div>
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Profile;
