import React from "react";
import { Col, Row } from "reactstrap";

function HopeDetail({ handleChange, handleOneCheck }) {
  return (
    <React.Fragment>
      <h3 className="MainText">이상형 상세정보</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <textarea
              className="js-input-mask form-control customInput"
              name="hopeStyle"
              id="hopeStyleLabel"
              placeholder="이상형 상세정보(를) 입력해 주세요."
              aria-label="이상형 상세정보(를) 입력해 주세요."
              onChange={handleChange}
              style={{ height: "300px", resize: "none" }}
            />
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-2">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <div className="">
              <input
                type="checkbox"
                className="form-check-input customCheckBox"
                id="privacy_agree"
                name="privacy_agree"
                onChange={handleOneCheck}
              />
              <label className="labelText marginLeft10" for="privacy_agree">
                개인정보 수집 및 이용에 동의합니다.
                <br />
                <span className="checkLabelText">
                  * 개인정보 및 상담내용은 철처히 보안이 유지됩니다.
                </span>
              </label>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default HopeDetail;
