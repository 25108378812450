import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";

import { requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PremiumMessageModal from "./Modal/PremiumMessageModal";

function GetLikeList({ id, resetAPI }) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [modalMsg, setModalMsg] = useState(null);

  const columns = [
    {
      dataField: "name",
      text: "이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.userId}`} style={{ color: "#495057" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "gender",
      text: "성별",
      sort: true,
    },
    {
      dataField: "grade",
      text: "등급",
      sort: true,
    },
    {
      dataField: "read",
      text: "읽음 여부",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "시간",
      sort: true,
    },
    {
      dataField: "type",
      text: "호감 종류",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <span
            onClick={() => {
              if (cellContent === "프리미엄") {
                return setModalMsg(row.message);
              }
            }}
          >
            {cellContent}
          </span>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const { SearchBar } = Search;

  const callInfo = useCallback(async () => {
    const memberResponse = await requestGet(`/api/like/recieved/list/${id}`);
    setMemberList(memberResponse?.data);
  }, [id]);

  useEffect(() => {
    if (id && resetAPI) {
      callInfo();
    }
  }, [callInfo, id, resetAPI]);

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          name: item.name,
          gender: item.gender,
          grade: item.grade,
          read: item.read,
          type:
            item.type === "premium"
              ? "프리미엄"
              : item.type === "normal"
              ? "일반"
              : "",
          userId: item.userId,
          message: item.message,
          createdAt: item.createdAt,
        })
      );
      setProductData(arr);
    }
  }, [memberList]);

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">받은 호감 목록</h3>
      <p className="card-title-desc"></p>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={columns}
        data={productData}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            columns={columns}
            data={productData}
            search
          >
            {(toolkitProps) => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={"id"}
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {modalMsg ? (
        <PremiumMessageModal Msg={modalMsg} setMsg={setModalMsg} />
      ) : null}
    </React.Fragment>
  );
}

export default GetLikeList;
