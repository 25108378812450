import React, { useCallback, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";

//Import Breadcrumb
import UserInfo from "./UserInfo/UserInfo";
import Adress from "./Adress/Adress";
import Profile from "./Profile/Profile";
import Sibling from "./Sibling/Sibling";
import Education from "./Education/Education";
import Company from "./Company/Company";
import Money from "./Money/Money";
import Family from "./Family/Family";
import "./user.scss";
import { requestGet } from "../../../apis/request";

function User(props) {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [data, setData] = useState(null);
  const [editData, setEditData] = useState(null);

  const { id } = useParams();

  const callAPI = useCallback(async () => {
    const response = await requestGet(`/api/member/join/detail/${id}`);
    setData(response.data);
  }, [id]);

  useEffect(() => {
    if (id) {
      callAPI();
    }
  }, [callAPI, id]);

  useEffect(() => {
    if (data !== null) {
      setEditData({
        address: data.address,
        appearanceScore: data.appearanceScore,
        assets: data.assets,
        birthday: data.birthday,
        brotherRelation: data.brotherRelation,
        character: data.character,
        comment: data.comment,
        counselorId: data.counselorId,
        education: data.education,
        email: data.email,
        familyInfomation: data.familyInfomation,
        gender: data.gender,
        height: data.height,
        hobby: data.hobby,
        id: data.id,
        idealInfo: data.idealInfo,
        isMarry: data.isMarry,
        job: data.job,
        licenses: data.licenses,
        marriageFee: data.marriageFee,
        military: data.military,
        name: data.name,
        phone: data.phone,
        profileId: data.profileId,
        regDate: data.regDate,
        selfIntro: data.selfIntro,
        solar: data.solar,
        specsScore: data.specsScore,
        ssn: data.ssn,
        totalScore: data.totalScore,
        userCode: data.userCode,
        wealthScore: data.wealthScore,
        weight: data.weight,
      });
    }
  }, [data]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 10) {
        var modifiedSteps = [...passedSteps, tab];
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>회원 정보</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {/* <div className="steps clearfix">
                    <ul>
                      <Row sm={5}>
                        <NavItem
                          className={classnames({
                            current: activeTab === 1,
                          })}
                        >
                          <NavLink
                            className={classnames(
                              {
                                current: activeTab === 1,
                              },
                              "truncate"
                            )}
                            onClick={() => {
                              setactiveTab(1);
                            }}
                          >
                            <span className="number">01</span> 유저 정보
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTab === 2,
                          })}
                        >
                          <NavLink
                            className={classnames(
                              {
                                active: activeTab === 2,
                              },
                              "truncate"
                            )}
                            onClick={() => {
                              setactiveTab(2);
                            }}
                          >
                            <span className="number">02</span> 주소 정보
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTab === 3,
                          })}
                        >
                          <NavLink
                            className={classnames(
                              {
                                active: activeTab === 3,
                              },
                              "truncate"
                            )}
                            onClick={() => {
                              setactiveTab(3);
                            }}
                          >
                            <span className="number">03</span> 프로필
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTab === 4,
                          })}
                        >
                          <NavLink
                            className={classnames(
                              {
                                active: activeTab === 4,
                              },
                              "truncate"
                            )}
                            onClick={() => {
                              setactiveTab(4);
                            }}
                          >
                            <span className="number">04</span> 형제 관계
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTab === 5,
                          })}
                        >
                          <NavLink
                            className={classnames(
                              {
                                active: activeTab === 5,
                              },
                              "truncate"
                            )}
                            onClick={() => {
                              setactiveTab(5);
                            }}
                          >
                            <span className="number">05</span> 학력 정보
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTab === 6,
                          })}
                        >
                          <NavLink
                            className={classnames(
                              {
                                active: activeTab === 6,
                              },
                              "truncate"
                            )}
                            onClick={() => {
                              setactiveTab(6);
                            }}
                          >
                            <span className="number">06</span> 직장정보
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({
                            current: activeTab === 7,
                          })}
                        >
                          <NavLink
                            className={classnames(
                              {
                                active: activeTab === 7,
                              },
                              "truncate"
                            )}
                            onClick={() => {
                              setactiveTab(7);
                            }}
                          >
                            <span className="number">07</span> 자산정보
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTab === 8,
                          })}
                        >
                          <NavLink
                            className={classnames(
                              {
                                active: activeTab === 8,
                              },
                              "truncate"
                            )}
                            onClick={() => {
                              setactiveTab(8);
                            }}
                          >
                            <span className="number">08</span> 가족정보
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTab === 9,
                          })}
                        >
                          <NavLink
                            className={classnames(
                              {
                                active: activeTab === 9,
                              },
                              "truncate"
                            )}
                            onClick={() => {
                              setactiveTab(9);
                            }}
                          >
                            <span className="number">09</span> 희망 상대
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTab === 10,
                          })}
                        >
                          <NavLink
                            className={classnames(
                              {
                                active: activeTab === 10,
                              },
                              "truncate"
                            )}
                            onClick={() => {
                              setactiveTab(10);
                            }}
                          >
                            <span className="number">10</span> 등급
                          </NavLink>
                        </NavItem>
                      </Row>
                    </ul>
                  </div> */}
                  <div className="wizard clearfix">
                    <div className="content clearfix mt-4">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          <UserInfo editData={editData} />
                        </TabPane>
                        <TabPane tabId={2}>
                          <Adress editData={editData} />
                        </TabPane>
                        <TabPane tabId={3}>
                          <Profile editData={editData} />
                        </TabPane>
                        <TabPane tabId={4}>
                          <Sibling editData={editData} />
                        </TabPane>
                        <TabPane tabId={5}>
                          <Education editData={editData} />
                        </TabPane>
                        <TabPane tabId={6}>
                          <Company editData={editData} />
                        </TabPane>
                        <TabPane tabId={7}>
                          <Money editData={editData} />
                        </TabPane>
                        <TabPane tabId={8}>
                          <Family editData={editData} />
                        </TabPane>
                        {/* <TabPane tabId={9}>
                          <Hope editData={editData} />
                        </TabPane> */}
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={activeTab === 8 ? "next disabled" : "next"}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default User;
