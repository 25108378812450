import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import LOGO from "../../assets/images/LOGO.png";
const Headercopy = (props) => {
  const [currentImg, setCurrentImg] = useState(0);
  // function toggleFullscreen() {
  //   if (
  //     !document.fullscreenElement &&
  //     /* alternative standard method */ !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement
  //   ) {
  //     // current working methods
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen();
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen();
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       document.documentElement.webkitRequestFullscreen(
  //         Element.ALLOW_KEYBOARD_INPUT
  //       );
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen();
  //     }
  //   }
  // }

  function tToggle() {
    /*
      set logic for changing sidebar
    */
    if (document.body.clientWidth >= 993) {
      //desktop view
      if (props.leftSideBarType === "default") {
        props.changeSidebarType("small");
      } else if (
        props.leftSideBarType === "small" ||
        props.leftSideBarType === "compact"
      ) {
        props.changeSidebarType("default");
      }
    } else {
      //mobile view
      document.body.classList.toggle("sidebar-enable");
      props.changeSidebarType("default");
    }
  }

  useEffect(() => {
    if (document.body.clientWidth < 993) {
      document.body.classList.remove("sidebar-enable");
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setCurrentImg(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div
              className="navbar-brand-box"
              style={
                currentImg < 993
                  ? {
                      padding: "0px",
                      textAlign: "center",
                      backgroundColor: "#F8F9FA",
                      borderBottom: "1px solid #E0E1E2",
                    }
                  : props.leftSideBarType === "default"
                  ? {
                      padding: "0px 39px 0px 34px",
                      backgroundColor: "#F8F9FA",
                      borderBottom: "1px solid #E0E1E2",
                    }
                  : {
                      padding: "0px",
                      textAlign: "center",
                      backgroundColor: "#F8F9FA",
                      borderBottom: "1px solid #E0E1E2",
                    }
              }
            >
              <Link
                to="/"
                className="logo logo-dark"
                style={
                  props.leftSideBarType === "default"
                    ? { padding: "0px 1.5rem" }
                    : null
                }
              >
                <span className="logo-sm" style={{ fontSize: "16px" }}>
                  <img alt="logopng" src={LOGO} />
                </span>
                <span
                  className="logo-lg"
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 700,
                    lineHeight: "150%",
                  }}
                >
                  <img
                    alt="logopng"
                    src={LOGO}
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "4px",
                    }}
                  />
                  우리의 인연
                </span>
              </Link>

              <Link
                to="/"
                className="logo logo-light"
                style={
                  props.leftSideBarType === "default"
                    ? { padding: "0px 1.5rem" }
                    : null
                }
              >
                <span className="logo-sm" style={{ fontSize: "16px" }}>
                  <img alt="logopng" src={LOGO} />
                </span>
                <span
                  className="logo-lg"
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 700,
                    lineHeight: "150%",
                  }}
                >
                  <img
                    alt="logopng"
                    src={LOGO}
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "4px",
                    }}
                  />
                  우리의 인연
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            {/* <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen" />
              </button>
            </div>

            <NotificationDropdown /> */}
            <ProfileMenu />
            {/* 
            <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Headercopy.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Headercopy));
