import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Row } from "reactstrap";
import "react-rangeslider/lib/index.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import "./schedule.scss";
import { adminRequestPost, requestGet } from "../../apis/request";
import moment from "moment";
import Calendars from "../../components/Calendars";
// import "./consult.scss";

registerLocale("ko", ko);

function Schedule(props) {
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [year, setYear] = useState(moment().format("YYYY"));
  const [month, setMonth] = useState(moment().format("MM"));

  const callApi = async () => {
    try {
      const params = new URLSearchParams();
      params.append("year", Number(year));
      params.append("month", Number(month));
      const response = await requestGet(`/api/match/connect/list?${params}`);
      setData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callApi();
  }, [year, month]);

  useEffect(() => {
    if (data?.length > 0) {
      let arr = [];
      data.forEach((item) => {
        if (item?.status !== "연결 강제 해제") {
          arr.push({
            id: item.id,
            date: new Date(item.meetingTime),
            receiverId: item.receiverId,
            senderId: item.senderId,
            senderName: item.senderName,
            receiverName: item.receiverName,
            meetingPlace: item.meetingPlace,
            meetingTime: moment(item.meetingTime).format("HH:mm"),
            description: item.memo,
          });
        }
      });
      setEditData(arr);
    }
  }, [data]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>매칭 연결 스케쥴</title>
        </MetaTags>
        {/* <div className="container"> */}
        <Card className="m-0">
          <CardBody>
            <div>
              <h2 style={{ paddingBottom: "24px" }}>매칭 연결 스케쥴</h2>
            </div>
            <div className="row">
              <div id="formContainer" className="col-lg-12">
                <div id="uploadResumeStepFormContent">
                  <Row>
                    <Calendars
                      data={editData}
                      setYear={setYear}
                      setMonth={setMonth}
                    />
                  </Row>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        {/* </div> */}
      </div>
    </React.Fragment>
  );
}

export default Schedule;
