import React from "react";
import { Row } from "reactstrap";

function Adress({ values, handleChange, SelectFn }) {
  const selOption = [
    {
      name: "결혼기간 시작날짜",
      key: "marriageStartDate",
      type: "date",
    },
    {
      name: "결혼기간 끝날짜",
      key: "marriageEndDate",
      type: "date",
    },
    {
      name: "이혼 사유",
      key: "reason",
      type: "text",
    },
    {
      name: "양육 유무",
      key: "care",
      type: "radio",
      value: ["양육중", "양육안함"],
    },
    {
      name: "자녀수",
      key: "childrenNumber",
      type: "number",
    },
  ];

  return (
    <React.Fragment>
      <h3 className="MainText">재혼 정보</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom" />
      {selOption?.map((el, i) => {
        if (el.type === "text") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else if (el.type === "number") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else if (el.type === "select") {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            values,
            handleChange,
          });
        } else if (el.type === "date") {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            values,
            handleChange,
          });
        } else {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            handleChange,
          });
        }
      })}
    </React.Fragment>
  );
}

export default Adress;
