import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";

import UserInfo from "./UserInfo/UserInfo";
import Adress from "./Adress/Adress";
import Profile from "./Profile/Profile";
import Education from "./Education/Education";
import Company from "./Company/Company";
import Money from "./Money/Money";
import Family from "./Family/Family";
import Hope from "./Hope/Hope";
import "./user.scss";
import { adminRequestPost, requestGet } from "../../apis/request";
import HopeHate from "./Hope/HopeHate";
import HopeDetail from "./Hope/HopeDetail";

function User(props) {
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [values, setValues] = useState({});
  const [attributes, setAttributes] = useState([]);
  // const [idealType, setIdealType] = useState({});
  const [sibling, setSibling] = useState(["sibling1"]);
  const [sido, setSido] = useState("");
  const [sidoTrue, setSidoTrue] = useState("");
  // const [publicImg, setPublicImg] = useState(null);
  const [locationData, setLocationData] = useState([]);
  // const [PriviteImg, setPriviteImg] = useState(null);

  const callInfo = async () => {
    try {
      const response = await requestGet("/api/attributes/list");
      setAttributes(response?.data?.industry?.value);
    } catch (error) {
      console.log(error);
    }
  };

  const callLocation = async () => {
    try {
      const response = await requestGet("/api/region/list/json");
      setLocationData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSidoChange = (e) => {
    if (e.target.name === "customAdress") {
      setSido(e.target.value);
    } else {
      setSidoTrue(e.target.value);
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleOneCheck = (e) => {
    const { name, checked } = e.target;
    setValues((prevValues) => {
      if (checked) {
        return {
          ...prevValues,
          [name]: true,
        };
      } else {
        return {
          ...prevValues,
          [name]: false,
        };
      }
    });
  };

  const handleCheck = (e) => {
    const { name, value, checked } = e.target;
    setValues((prevValues) => {
      const existingValues = prevValues[name] || [];
      if (checked) {
        return {
          ...prevValues,
          [name]: [...existingValues, value],
        };
      } else {
        return {
          ...prevValues,
          [name]: existingValues.filter((item) => item !== value),
        };
      }
    });
  };

  const handleMoneyChange = (e) => {
    const value = e.target.value?.replace(/\D/g, ""); // 숫자가 아닌 모든 문자 제거
    const formattedNumber = value?.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 천 단위마다 콤마 추가
    setValues({
      ...values,
      [e.target.name]: formattedNumber,
    });
  };

  const handleDropChange = (name) => (selectedOption) => {
    setValues({
      ...values,
      [name]: selectedOption.value,
    });
  };

  useEffect(() => {
    window.location.href = "https://www.coincidence.co.kr/register";
    callInfo();
    callLocation();
  }, []);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 10) {
        var modifiedSteps = [...passedSteps, tab];
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
        window.scrollTo(0, 0);
      }
    }
  }

  const onSubmitApi = async () => {
    if (!values.privacy_agree) {
      alert("개인정보 수집 및 이용에 동의하여야 등록 가능합니다.");
      return;
    }
    try {
      let body = {
        name: values.name,
        gender: values.gender,
        birthday: values.birth,
        isMarry: values.isMarry,
        character: values.mystyle,
        height: values.height ? Number(values.height) : null,
        weight: values.weight ? Number(values.weight) : null,
        home: values.customAdressdetail,
        homeRegionCode: values.customAdressgoogun,
        parentHome: values.tureHomedetail,
        parentHomeRegionCode: values.tureHomegoogun,
        drink: values.drink,
        smoke: values.smoke,
        bloodType: values.bloodType,
        tatoo: values.tatoo,
        exercise: values.sport,
        military: values.army,
        religion: values.gido,
        selfAppearance: values.selfAppearance,
        mbti: values.mbti ? values.mbti.toUpperCase() : "",
        bodyCharacter: values.bodyCharacter,
        marriageStartDate: values.marriageStartDate,
        marriageEndDate: values.marriageEndDate,
        reason: values.reason,
        care: values.care,
        childrenNumber: values.childrenNumber,
        hobby: values.myhobby,
        drinkBottleLimit: Number(values.drinkBottleLimit),
        drinkCupLimit: Number(values.drinkCupLimit),
        status: "complete",
        education: {
          finalStage: values.finaledu,
          univName: values.hakname,
          univMajor: values.hakskill,
          univGradyear: values.hakenddate ? Number(values.hakenddate) : null,
          gradSchoolName: values.sukname,
          gradSchoolMajor: values.sukskill,
          gradSchoolGradyear: values.sukenddate
            ? Number(values.sukenddate)
            : null,
        },
        jobinfo: {
          job: values.jobname,
          career: values.career,
          extraJob: values.etcjobname,
          employmentType: values.jobstatus,
          workType: values.workstatus,
          yearIncome: values.yearmoney
            ? Number(values.yearmoney?.replace(/\D/g, ""))
            : 0,
          name: values.comname,
          department: values.department,
          position: values.level,
          address: values.jobaddres,
        },
        assets: {
          movableAmount: values.movemoney
            ? Number(values.movemoney?.replace(/\D/g, ""))
            : 0,
          immovableAmount: values.nomovemoney
            ? Number(values.nomovemoney?.replace(/\D/g, ""))
            : 0,
          hasImmovable: values.hasnomovemoney,
          assistance: values.merrymoney,
          hasCar: values.hascar,
          carModelName: values.carname,
          madeBy: values.makecarname,
          assistanceExtra: values.assistanceExtra
            ? Number(values.assistanceExtra?.replace(/\D/g, ""))
            : 0,
        },
        familyRelation: {
          type: values.familyinfo,
          brothers: values.brothers ? Number(values.brothers) : null,
          sisters: values.sisters ? Number(values.sisters) : null,
          birthOrder: values.birthOrder ? Number(values.birthOrder) : null,
          fatherJob: values.fajob,
          motherJob: values.mojob,
          parentAsset: values.pamoney
            ? Number(values.pamoney?.replace(/\D/g, ""))
            : 0,
          withParent: values.pawithlive,
          parentIndep: values.paalone,
          isSplit: values.paend,
          parentStatus: values.pasad,
        },

        idealData: {
          birthYearMin: values.ideal_birthYearMin,
          birthYearMax: values.ideal_birthYearMax,
          heightMin: values.ideal_heightMin,
          heightMax: values.ideal_heightMax,
          education: values.ideal_education,
          appearance: values.ideal_appearance,
          industry: values.ideal_industry,
          income: values.ideal_income,
          personalAsset: values.ideal_personalAsset,
          hasImmovable: values.ideal_hasImmovable,
          parentalAsset: values.ideal_parentalAsset,
          religion: values.ideal_religion,
          allowRegion: JSON.stringify(values.ideal_allowRegion),
          character: values.ideal_caracter,
          priority: values.ideal_priority,
          hateIndustry: values.hateIndustry,
          hateMarriageHistory: values.hateMarriageHistory,
          hateSmokeType: values.hateSmokeType,
          hateTatoo: values.hateTatoo,
          hateParentStatus: values.hateParentStatus,
          hateHasCarType: values.hateHasCarType,
          hateEmploymentType: JSON.stringify(values.hateEmploymentType),
          hateInterest: values.hateInterest,
          idealDetail: values.hopeStyle,
          priority1: values.priority1,
          priority2: values.priority2,
          priority3: values.priority3,
        },
      };
      await adminRequestPost(`/api/member/register`, body);
      // const response = await adminRequestPost(`/api/member/register`, body);
      // if (publicImg) {
      //   var bodyFormData = new FormData();
      //   bodyFormData.append("profileId", response.profileId);
      //   bodyFormData.append("userId", response.userId);
      //   bodyFormData.append("isPublic", true);
      //   Object.keys(publicImg).map((item) => {
      //     bodyFormData.append("files", publicImg[item]);
      //   });
      //   const formresponse = await formPost(
      //     `/api/file/uploads/v2`,
      //     bodyFormData
      //   );
      // }
      // if (PriviteImg) {
      //   var bodyFormData = new FormData();
      //   bodyFormData.append("profileId", response.profileId);
      //   bodyFormData.append("userId", response.userId);
      //   bodyFormData.append("isPublic", false);
      //   Object.keys(PriviteImg).map((item) => {
      //     bodyFormData.append("files", PriviteImg[item]);
      //   });
      //   const formresponse = await formPost(
      //     `/api/file/uploads/v2`,
      //     bodyFormData
      //   );
      // }
      alert("요청 성공 하였습니다.");
      window.location.reload();
    } catch (error) {
      console.log(error);
      alert("전송 실패하였습니다.");
    }
  };

  function numberToKorean(number) {
    let num = number ? number : "0";
    if (typeof number === "string") {
      num = number.replace(/\D/g, "");
    }
    const units = ["", "만", "억", "조", "경"];
    const nums = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
    let result = "";
    let unit = 10000;

    if (num === 0) {
      return "영";
    }

    for (let u of units) {
      const curr = num % unit;
      num = Math.floor(num / unit);

      if (curr !== 0) {
        let currStr = "";
        const currArr = String(curr).split("").reverse();

        for (let i = 0; i < currArr.length; i++) {
          const digit = currArr[i];
          if (digit !== "0") {
            currStr =
              nums[parseInt(digit)] +
              (i > 0 ? ["", "십", "백", "천"][i] : "") +
              currStr;
          }
        }
        result = currStr + u + result;
      }

      if (num === 0) {
        break;
      }
    }

    // "일"을 제거하는 조건을 보다 구체적으로 설정하여, "일억"과 같은 경우에는 "일"을 제거하지 않음
    return (result += "원");
  }

  const handleCheckstr = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      let updatedArray = prevValues[name] ? [...prevValues[name]] : [];
      if (prevValues[name]?.includes(value)) {
        const arr = updatedArray.filter((_value) => _value !== value);
        updatedArray = arr;
      } else {
        updatedArray.push(value);
      }
      return { ...prevValues, [name]: updatedArray };
    });
  };

  function SelectFn({
    name,
    keyProp,
    values,
    value,
    type,
    handleChange,
    description,
    handleMoneyChange,
  }) {
    if (type === "text") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="text"
                className="js-input-mask form-control customInput"
                name={keyProp}
                id={`${keyProp}Label`}
                placeholder={`${name}을(를) 입력해 주세요.`}
                aria-label={`${name}을(를) 입력해 주세요.`}
                onChange={handleChange}
              />
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "money") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="text"
                value={values?.[keyProp]}
                className="js-input-mask form-control customInput"
                name={keyProp}
                id={`${keyProp}Label`}
                placeholder={`${name}을(를) 입력해 주세요.`}
                aria-label={`${name}을(를) 입력해 주세요.`}
                onChange={handleMoneyChange}
              />
              <div>{numberToKorean(values?.[keyProp])}</div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "number") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="number"
                className="js-input-mask form-control customInput"
                name={keyProp}
                id={`${keyProp}Label`}
                onWheel={(e) => e.target.blur()}
                placeholder={`${name}을(를) 입력해 주세요.`}
                aria-label={`${name}을(를) 입력해 주세요.`}
                onChange={handleChange}
              />
              {description && (
                <div className="descriptionText">{description}</div>
              )}
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "date") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <input
                type="date"
                className="js-input-mask form-control customInputLeft"
                name={keyProp}
                id={`${keyProp}Label`}
                onChange={handleChange}
                style={
                  !values?.[keyProp]
                    ? {
                        color: "#BCBCBC",
                      }
                    : { color: "#333" }
                }
              />
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "location") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <Row>
                <Col>시/도</Col>
              </Row>
              <select
                id="counselor_genderLabel"
                className="form-select customInput"
                name={keyProp}
                value={values?.[keyProp]}
                onChange={handleSidoChange}
                // style={
                //   !values?.[keyProp]
                //     ? {
                //         color: "#BCBCBC",
                //       }
                //     : { color: "#333" }
                // }
              >
                {!values?.[keyProp] ? (
                  <option>{`${name}을(를) 선택해 주세요.`}</option>
                ) : null}
                {locationData.map((item, i) => {
                  return (
                    <option value={item.name} key={i}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <Row>
                <Col>시/구/군</Col>
              </Row>
              <select
                id="counselor_genderLabel"
                className="form-select customInput"
                name={`${keyProp}googun`}
                value={values?.[`${keyProp}googun`]}
                onChange={handleChange}
                // style={
                //   !values?.[`${keyProp}googun`]
                //     ? {
                //         color: "#BCBCBC",
                //       }
                //     : { color: "#333" }
                // }
              >
                {!values?.[`${keyProp}googun`] ? (
                  <option>{`${name}을(를) 선택해 주세요.`}</option>
                ) : null}
                {locationData.map((item, i) => {
                  let str = keyProp === "customAdress" ? sido : sidoTrue;
                  if (item?.name === str) {
                    return item.list.map((el, j) => {
                      return (
                        <option value={el.regionCode} key={j}>
                          {el.name}
                        </option>
                      );
                    });
                  }
                })}
              </select>
              <div>
                <Row>
                  <Col>상세입력</Col>
                </Row>
                <input
                  type="text"
                  className="js-input-mask form-control customInput"
                  name={`${keyProp}detail`}
                  id={`${keyProp}detailLabel`}
                  placeholder={`${name}을(를) 입력해 주세요.`}
                  aria-label={`${name}을(를) 입력해 주세요.`}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "select") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <select
                id="counselor_genderLabel"
                className="form-select customInput"
                name={keyProp}
                onChange={handleChange}
                style={
                  !values?.[keyProp]
                    ? {
                        color: "#BCBCBC",
                      }
                    : { color: "#333" }
                }
              >
                {!values?.[keyProp] ? (
                  <option>{`${name}을(를) 선택해 주세요.`}</option>
                ) : null}
                {value?.map((item, i) => {
                  return (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "brothers") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`brothersLabel`} className="labelText">
                {name}
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="number"
                  className="js-input-mask form-control customInput"
                  name="brothers"
                  id={`brothersLabel`}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  style={{
                    width: "44px",
                    padding: "0.47rem 0px 0.47rem 0.75rem",
                    textAlign: "end",
                  }}
                />
                <label className="textLabel mx-2 my-0">남</label>
                <input
                  type="number"
                  className="js-input-mask form-control customInput"
                  name="sisters"
                  id={`sistersLabel`}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  style={{
                    width: "44px",
                    padding: "0.47rem 0px 0.47rem 0.75rem",
                    textAlign: "end",
                  }}
                />
                <label className="textLabel mx-2 my-0">녀</label>
                <input
                  type="number"
                  className="js-input-mask form-control customInput"
                  name="birthOrder"
                  id={`birthOrderLabel`}
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChange}
                  style={{
                    width: "44px",
                    padding: "0.47rem 0px 0.47rem 0.75rem",
                    textAlign: "end",
                  }}
                />
                <label className="textLabel mx-2 my-0">째</label>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "radio") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {value.map((item, index) => {
                  if (item === "기타") {
                    return (
                      <div
                        key={index}
                        className="d-flex mr20 mb-2 align-items-center"
                      >
                        <input
                          type="radio"
                          name={keyProp}
                          value={item}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                          }}
                          // onChange={handleChange}
                        />
                        <label className="textLabel m-0">{item}</label>
                        <input
                          type="text"
                          className="js-input-mask form-control customInput"
                          name={keyProp}
                          id={`${keyProp}Label`}
                          placeholder={`내용을 입력해 주세요.`}
                          aria-label={`내용을 입력해 주세요.`}
                          style={{ marginLeft: "10px", width: "200px" }}
                          onChange={handleChange}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="d-flex mr20 mb-2">
                        <input
                          type="radio"
                          name={keyProp}
                          value={item}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                          }}
                          onChange={handleChange}
                        />
                        <label className="textLabel m-0">{item}</label>
                      </div>
                    );
                  }
                })}
                {name === "주량" ? (
                  <div className="d-flex" style={{ alignItems: "flex-start" }}>
                    <label className="textLabel">소주</label>
                    <input
                      type="number"
                      className="js-input-mask customInput"
                      name="drinkBottleLimit"
                      id={`${keyProp}Label`}
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange}
                      style={{
                        width: "44px",
                        padding: "0px 0px 0px 0.7rem",
                        textAlign: "end",
                      }}
                    />
                    <label className="textLabel">병</label>
                    <input
                      type="number"
                      className="js-input-mask customInput"
                      name="drinkCupLimit"
                      id={`${keyProp}Label`}
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChange}
                      style={{
                        width: "44px",
                        padding: "0px 0px 0px 0.7rem",
                        textAlign: "end",
                      }}
                    />
                    <label className="textLabel">잔</label>
                  </div>
                ) : null}
                {name === "혼인 시 경제적 지원" ? (
                  <div>
                    <input
                      type="text"
                      className="js-input-mask form-control customInput"
                      name="assistanceExtra"
                      id={`assistanceExtraLabel`}
                      value={values["assistanceExtra"]}
                      placeholder={`금액을 입력해 주세요.`}
                      aria-label={`금액을 입력해 주세요.`}
                      disabled={values?.merrymoney === "있음" ? false : true}
                      onChange={handleMoneyChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
          {name === "혼인 시 경제적 지원" ? (
            values?.merrymoney === "있음" ? (
              <Col lg={12} className="d-flex flex-column">
                <div>{numberToKorean(values?.["assistanceExtra"])}</div>
              </Col>
            ) : null
          ) : null}
        </Row>
      );
    }
    if (type === "check") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {attributes?.map((item, index) => (
                  <div key={index} className="d-flex mr20 mb-2">
                    <input
                      type="checkbox"
                      name={keyProp}
                      value={item.id}
                      checked={values?.[keyProp]?.includes(item.id)}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleCheck(e)}
                    />
                    <label className="textLabel m-0">{item.value}</label>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
    if (type === "checks") {
      return (
        <Row className="rowBorderBottom pt-3 pb-3">
          <Col lg={12} className="d-flex flex-column">
            <div>
              <label htmlFor={`${keyProp}Label`} className="labelText">
                {name}
              </label>
              <div className="d-flex pt-2 radiowrap">
                {value?.map((item, index) => (
                  <div key={index} className="d-flex mr20 mb-2">
                    <input
                      type="checkbox"
                      name={keyProp}
                      value={item}
                      checked={values?.[keyProp]?.includes(item)}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                      onClick={(e) => handleCheckstr(e)}
                    />
                    <label className="textLabel m-0">{item}</label>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>회원 등록</title>
        </MetaTags>
        <Container>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          <UserInfo
                            values={values}
                            handleChange={handleChange}
                            SelectFn={SelectFn}
                          />
                        </TabPane>
                        <TabPane tabId={2}>
                          <Education
                            handleChange={handleChange}
                            handleOneCheck={handleOneCheck}
                            SelectFn={SelectFn}
                          />
                        </TabPane>
                        <TabPane tabId={3}>
                          <Company
                            handleChange={handleChange}
                            attributes={attributes}
                            SelectFn={SelectFn}
                            values={values}
                            handleMoneyChange={handleMoneyChange}
                          />
                        </TabPane>
                        <TabPane tabId={4}>
                          <Money
                            handleChange={handleChange}
                            attributes={attributes}
                            SelectFn={SelectFn}
                            values={values}
                            handleMoneyChange={handleMoneyChange}
                          />
                        </TabPane>
                        <TabPane tabId={5}>
                          <Family
                            handleChange={handleChange}
                            handleDropChange={handleDropChange}
                            attributes={attributes}
                            values={values}
                            sibling={sibling}
                            setSibling={setSibling}
                            SelectFn={SelectFn}
                            handleMoneyChange={handleMoneyChange}
                          />
                        </TabPane>
                        <TabPane tabId={6}>
                          <Adress
                            handleChange={handleChange}
                            handleDropChange={handleDropChange}
                            attributes={attributes}
                            values={values}
                            sibling={sibling}
                            setSibling={setSibling}
                            SelectFn={SelectFn}
                          />
                        </TabPane>
                        <TabPane tabId={7}>
                          <Profile handleChange={handleChange} />
                        </TabPane>
                        <TabPane tabId={8}>
                          <Hope
                            handleChange={handleChange}
                            attributes={attributes}
                            // idealType={idealType}
                            handleCheck={handleCheck}
                            SelectFn={SelectFn}
                            handleCheckstr={handleCheckstr}
                            values={values}
                          />
                        </TabPane>
                        <TabPane tabId={9}>
                          <HopeHate
                            handleChange={handleChange}
                            attributes={attributes}
                            // idealType={idealType}
                            handleCheck={handleCheck}
                            SelectFn={SelectFn}
                            handleCheckstr={handleCheckstr}
                            values={values}
                          />
                        </TabPane>
                        <TabPane tabId={10}>
                          <HopeDetail
                            handleChange={handleChange}
                            attributes={attributes}
                            // idealType={idealType}
                            handleOneCheck={handleOneCheck}
                            values={values}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            이전
                          </Link>
                        </li>
                        <li
                          className={
                            activeTab === 10 ? "next disabled" : "next"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            다음
                          </Link>
                        </li>
                        <li className={activeTab !== 10 ? "d-none" : "finish"}>
                          <Link
                            to="#"
                            onClick={() => {
                              onSubmitApi();
                            }}
                          >
                            완료
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default User;
