// src/App.js
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendars.scss";

function Calendars({ data, setYear, setMonth }) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [schedules, setSchedules] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(date);
  };

  const handleAddSchedule = () => {
    const newSchedule = {
      date: selectedDate,
      title: title,
      description: description,
    };
    setSchedules([...schedules, newSchedule]);
    setTitle("");
    setDescription("");
  };

  const schedulesForSelectedDate = schedules.filter(
    (schedule) => schedule.date.toDateString() === selectedDate.toDateString()
  );

  const handleActiveStartDateChange = ({ activeStartDate, view }) => {
    if (view === "month") {
      setMonth(activeStartDate.getMonth() + 1);
      setYear(activeStartDate.getFullYear());
    }
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const daySchedules = data?.filter(
        (schedule) => schedule?.date?.toDateString() === date.toDateString()
      );
      return (
        <div className="w-100">
          {daySchedules.map((schedule, index) => (
            <div key={index} className="schedule-chip">
              <a
                href={`/member/detail/${schedule.senderId}`}
                style={{ color: "#fff" }}
              >
                {schedule.senderName}
              </a>
              ,
              <a
                href={`/member/detail/${schedule.receiverId}`}
                style={{ color: "#fff" }}
              >
                {schedule.receiverName}
              </a>
              {schedule.meetingTime},{schedule.meetingPlace}
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="App">
      <div className="calendar-container">
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          tileContent={tileContent}
          onActiveStartDateChange={handleActiveStartDateChange}
        />
      </div>
    </div>
  );
}

export default Calendars;
