import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

function Family({ editData }) {
  return (
    <React.Fragment>
      <h2>가족정보</h2>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      {editData?.familyInfomation?.map((item, i) => {
        return (
          <div key={i}>
            <Row className="rowBorderBottom mt-3">
              <h2>{item.relation}</h2>
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom05">이름</Label>
                  <div>{item?.name}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="rowBorderBottom mt-3">
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">생년</Label>
                  <div>{item?.birthYear}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="rowBorderBottom mt-3">
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">최종학력</Label>
                  <div>{item?.education}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="rowBorderBottom mt-3">
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">최종학력학교</Label>
                  <div>{item?.finalSchoolName}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="rowBorderBottom mt-3">
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">직업</Label>
                  <div>{item?.job}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="rowBorderBottom mt-3">
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">직장명</Label>
                  <div>{item?.jobName}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="rowBorderBottom mt-3">
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">현재 재직여부</Label>
                  <div>{item?.currentJob ? "재직중" : "휴직중"}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="rowBorderBottom mt-3">
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">결혼여부</Label>
                  <div>{item?.isMarry ? "유" : "무"}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="rowBorderBottom mt-3">
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">상태</Label>

                  <div>{item?.status}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="rowBorderBottom mt-3">
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">거주지</Label>
                  <div>{item?.home}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="rowBorderBottom mt-3">
              <Col md="10">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom04">추가설명사항</Label>
                  <div>{item?.commnet}</div>
                </FormGroup>
              </Col>
            </Row>
          </div>
        );
      })}
    </React.Fragment>
  );
}

export default Family;
