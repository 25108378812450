import axios from "axios";


// import Cookies from "js-cookie";
//  export const apiUrl = "http://43.203.0.1";
  // export const apiUrl = "https://manager.coincidence.co.kr";

let flag = false;

export const setToken = (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const form = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "multipart/form-data",
    withCredentials: true,
  },
});

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
});

export const noclient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
});

export const update = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

form.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      if (!flag) {
        if (window.location.pathname !== "/login") {
          // onlogin();
          flag = true;
          window.location.pathname = "/login";
          // reUserPut("/api/account/update/refresh");
        }
      }
    }
    return error;
    //  else if (error.response?.status < 200 || error.response?.status > 299) {
    //   return alert(error.response.data.message);
    // }
  }
);

noclient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      if (!flag) {
        if (window.location.pathname !== "/login") {
          // onlogin();
          flag = true;
          window.location.pathname = "/login";
          // reUserPut("/api/account/update/refresh");
        }
      }
    }
    return error;
    //  else if (error.response?.status < 200 || error.response?.status > 299) {
    //   return alert(error.response.data.message);
    // }
  }
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      if (!flag) {
        if (window.location.pathname !== "/login") {
          // onlogin();
          flag = true;
          window.location.pathname = "/login";
          // reUserPut("/api/account/update/refresh");
        }
      }
    }
    //  else if (error.response?.status < 200 || error.response?.status > 299) {
    //   return alert(error.response.data.message);
    // }
    return error;
  }
);

update.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 409) {
      // alert("중복된 요청 입니다.");
    } else if (error.response?.status === 429) {
      alert("소개 대상이 일주일간 20회 이상 소개 되었습니다.");
    } else if (error.response?.status === 401) {
      if (!flag) {
        if (window.location.pathname !== "/login") {
          // onlogin();
          flag = true;
          window.location.pathname = "/login";
          // reUserPut("/api/account/update/refresh");
        }
      }
    }
    //  else if (error.response?.status < 200 || error.response?.status > 299) {
    //   return alert(error.response.data.message);
    // }
    // return Promise.reject(error);

    return error;
  }
);

export const loginPost = async (url, body) => {
  try {
    const res = await client.post(url, body);
    if (res?.status >= 200 || res?.status <= 299) {
      return res.data;
    } else {
      let message;
      if (res?.status) {
        switch (res?.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = res.message;
            break;
        }
      }
      alert(message);
    }
  } catch (error) {
    alert("아이디와 비밀번호를 확인해주세요");
  }
};

export const requestPost = async (url, body) => {
  const token = localStorage.getItem("merriageAgency-access");
  let headers = {};
  if (token) {
    headers = {
      "x-access-token": token,
    };
  }
  const res = await client.post(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    throw res.response;
    // throw message;
  }
};

export const adminRequestPost = async (url, body) => {
  const token = localStorage.getItem("merriageAgency-access");
  let headers = {};
  if (token) {
    headers = {
      "x-access-token": token,
    };
  }

  const res = await update.post(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    throw res.response;
    // throw message;
  }
};

export const formPost = async (url, body) => {
  const token = localStorage.getItem("merriageAgency-access");
  let headers = {};
  if (token) {
    headers = {
      "x-access-token": token,
    };
  }
  // const res = await client.post(url, body, { headers });
  const res = await form.post(url, body, { headers });
  if (res.status >= 200 || res.status <= 299) {
    return res.data;
  } else {
    let message;
    if (res.status) {
      switch (res.status) {
        case 409:
          message = "요청이 존재합니다.";
          break;
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = "";
          break;
      }
    }
    return alert(message);
  }
};

export const requestPut = async (url, body) => {
  const token = localStorage.getItem("merriageAgency-access");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-access-token": token,
    };
  }
  const res = await client.put(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    throw res.response;
    // throw message;
  }
};

export const adminUserPut = async (url, body) => {
  const token = localStorage.getItem("merriageAgency-access");
  let headers = {};
  if (token) {
    headers = {
      "x-access-token": token,
    };
  }
  const res = await update.put(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    throw res.response;
    // throw message;
  }
};

export const reUserPut = async (url, body) => {
  const token = localStorage.getItem("merriageAgency-access");
  const longtoken = localStorage.getItem("long");
  let headers = {};
  if (token) {
    headers = {
      "x-jwt-refresh": `${longtoken}`,
      "x-access-token": token,
    };
  }
  const res = await update.put(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    sessionStorage.setItem("authUser", res.data.accessToken);
    localStorage.setItem("long", res.data.refreshToken);
    flag = false;
    window.location.reload();
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    throw res.response;
    // throw message;
  }
};

export const requestGet = async (url) => {
  const token = localStorage.getItem("merriageAgency-access");
  let headers = {};
  if (token) {
    headers = {
      "x-access-token": token,
    };
  }
  const res = await client.get(url, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    throw res.response;
    // throw message;
  }
};
export const norequestGet = async (url) => {
  const token = localStorage.getItem("merriageAgency-access");
  let headers = {};
  if (token) {
    headers = {
      "x-access-token": token,
    };
  }
  const res = await noclient.get(url, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    throw res.response;
    // throw message;
  }
};

export const requestDelete = async (url) => {
  const token = localStorage.getItem("merriageAgency-access");
  let headers = {};
  if (token) {
    headers = {
      "x-access-token": token,
    };
  }
  const res = await client.delete(url, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    throw res.response;
    // throw message;
  }
};

export const imgPut = async (url, body) => {
  const token = localStorage.getItem("merriageAgency-access");
  let headers = {};
  if (token) {
    headers = {
      "Content-Type": "multipart/form-data",
      "x-access-token": token,
    };
  }
  const res = await client.put(url, body, { headers });
  if (res?.status >= 200 || res?.status <= 299) {
    return res.data;
  } else {
    // let message;
    // if (res?.status) {
    //   switch (res?.status) {
    //     case 404:
    //       message = "Sorry! the page you are looking for could not be found";
    //       break;
    //     case 500:
    //       message =
    //         "Sorry! something went wrong, please contact our support team";
    //       break;
    //     case 401:
    //       message = "Invalid credentials";
    //       break;
    //     default:
    //       message = res.message;
    //       break;
    //   }
    // }
    throw res.response;
    // throw message;
  }
};
