import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLikeListStore } from "../../store/zustand/state";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { adminUserPut,adminRequestPost, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";
import PremiumMessageModal from "./Modal/PremiumMessageModal";

function LikeList(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [modalMsg, setModalMsg] = useState(null);
  const [managers, setManagers] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const [name, setName] = useState("");
  const [manager, setManager] = useState("");
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const { list, updateList, updateActiveNum } = useLikeListStore();

  const columns = [
    {
      dataField: "status",
      text: "상태",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <span
            onClick={() => {
              if (cellContent === "거절" || cellContent === "거절 예약") {
                return setModalMsg(row.answer);
              }
            }}
          >
            {cellContent}
          </span>
        );
      },
    },
    {
      dataField: "senderName",
      text: "호감 보낸 사람 이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a href={`/member/detail/${row.senderId}`} style={{ color: "#000" }}>
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "receiverName",
      text: "호감 받은 사람 이름",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <a
            href={`/member/detail/${row.receiverId}`}
            style={{ color: "#000" }}
          >
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "viewDate",
      text: "호감 읽음 여부",
      sort: true,
    },
    
    {
      dataField: "cancelLike",
      text: "호감 취소",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        if (row.viewDate === "false" && row.status === "요청중") {
          return (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                callCancel(row.id);
              }}
            >
              호감 취소
            </button>
          );
        }
      },
    },
    {
      dataField: "createdAt",
      text: "호감 일자",
      sort: true,
    },
    {
      dataField: "type",
      text: "호감 종류",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <span
            onClick={() => {
              return setModalMsg(row.message);
            }}
          >
            {cellContent}
          </span>
        );
      },
    },
    {
      dataField: "reservedTime",
      text: "거절 예약 시간",
      sort: true,
    },
    {
      dataField: "action",
      text: "거절취소",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        if (row.status === "거절" || row.status === "거절 예약") {
          return (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                callReject(row.id);
              }}
            >
              거절취소
            </button>
          );
        }
      },
    },
  ];

  const defaultSorted = [];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  };

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  const callManagerApi = async () => {
    try {
      const response = await requestGet(`/api/counseling/manager/list`);
      setManagers(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callManagerApi();
  }, []);

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      const memberResponse = await requestGet(`/api/like/list?${params}`);
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  const callReject = async (id) => {
    if (window.confirm("정말 취소 하시겠습니까?")) {
      try {
        let body = {
          id: id,
        };
        await adminRequestPost(`/api/like/reject/cancel`, body);
        alert("취소 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert(error.data.message);
      }
    }
  };
  const callCancel = async (id) => {
    if (window.confirm("호감을 취소 하시겠습니까?")) {
      try {
        let body = {
          id: id,
        };
        await adminUserPut(`/api/like/cancel`, body);
        alert("취소 하였습니다.");
        window.location.reload();
      } catch (error) {
        alert(error.data.message);
      }
    }
  };

  useEffect(() => {
    setActiveNum(list.page);
    setName(list.name);
    setManager(list.manager);
    callInfo();
  }, [callInfo, list]);

  const gohistory = () => {
    updateList(1, name, manager);
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          senderGender: item.senderGender,
          senderName: (item.senderName +=
            item.senderGender === "남성"
              ? "(남)"
              : item.senderGender === "여성"
              ? "(여)"
              : ""),
          receiverGender: item.receiverGender,
          receiverPhone: item.receiverPhone,
          receiverId: item.receiverId,
          reservedTime: item.reservedTime,
          createdAt: item.createdAt,
          senderPhone: item.senderPhone,
          senderId: item.senderId,
          status:
            item.status === "request"
              ? "요청중"
              : item.status === "accept"
              ? "수락"
              : item.status === "cancel"
              ? "취소"
              : item.status === "reject" && item.reservedTime
              ? "거절 예약"
              : item.status === "reject" && !item.reservedTime
              ? "거절"
              : "",
          receiverName: (item.receiverName +=
            item.receiverGender === "남성"
              ? "(남)"
              : item.receiverGender === "여성"
              ? "(여)"
              : ""),
          type:
            item.type === "premium"
              ? "프리미엄"
              : item.type === "normal"
              ? "일반"
              : "",
          message: item.message,
          answer: item.answer,
          viewDate: item.viewDate ? "true" : "false",
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  useEffect(() => {
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, productData]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>호감 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>호감 목록</h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <Row
                            className="pb-4 m-0"
                            style={{ position: "sticky", left: 0 }}
                          >
                            <Col lg="12" className="p-0">
                              <Row className="rowBorderBottom w-100 m-0  customSearchInputWrap">
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-between"
                                  style={{
                                    padding: "0.47rem 0.75rem",
                                    backgroundColor: "#ccc",
                                    fontWeight: 700,
                                  }}
                                >
                                  <div>조회 조건</div>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>이름</span>
                                    </Col>
                                    <Col className="m-0 p-0">
                                      <div className="h-100">
                                        <input
                                          type="text"
                                          className="js-input-mask form-control customSearchInput h-100"
                                          name="name"
                                          id="nameLabel"
                                          placeholder="이름을 입력해 주세요."
                                          aria-label="이름을 입력해 주세요."
                                          value={name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                          // onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <Row className="w-100 m-0">
                                    <Col
                                      lg={2}
                                      className="d-flex align-items-center"
                                      style={{
                                        padding: "0.47rem 0px 0.75rem 0.47rem",
                                        backgroundColor: "#ccc",
                                        fontWeight: 700,
                                        borderTop: "0.3px solid #fff",
                                        borderBottom: "0.3px solid #fff",
                                      }}
                                    >
                                      <span>매니저 이름</span>
                                    </Col>
                                    <Col
                                      className="m-0 p-0"
                                      style={{
                                        borderBottom: "1px solid #ced4da",
                                      }}
                                    >
                                      <div className="h-100">
                                        <select
                                          id="counselor_genderLabel"
                                          className="noborder form-select h-100"
                                          value={manager}
                                          onChange={(e) =>
                                            setManager(e.target.value)
                                          }
                                          style={{ width: "100%" }}
                                        >
                                          <option value="">전체</option>
                                          {managers?.map((item, i) => {
                                            return (
                                              <option value={item.name} key={i}>
                                                {item.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} className="d-flex p-0 flex-column">
                                  <div>
                                    <Button
                                      className="w-100"
                                      color="primary"
                                      style={{ borderRadius: "0px" }}
                                      onClick={() => gohistory()}
                                    >
                                      조회하기
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <div className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </div>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {modalMsg ? (
        <PremiumMessageModal Msg={modalMsg} setMsg={setModalMsg} />
      ) : null}
    </React.Fragment>
  );
}

export default LikeList;
