import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import "./modal.scss";
import { adminRequestPost } from "../../../apis/request";
import moment from "moment";

function ConnectModal({
  connectID,
  setConnectID,
  setConnectModal,
  date,
  setDate,
  time,
  setTime,
  MM,
  setMM,
  place,
  setPlace,
  etc,
  setEtc,
  memo,
  setMemo,
  manager,
  setManager,
}) {
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const callConnect = async () => {
    if (!manager) {
      return alert("매니저를 선택해주세요.");
    }
    if (window.confirm("연결 하시겠습니까?"))
      try {
        let body = {
          id: connectID,
          meetingPlace: place,
          etc: etc,
          memo: memo,
          manager: manager,
        };
        if (date && time) {
          body.meetingTime = `${moment(date).format("YYYY-MM-DD")} ${
            Number(time) < 10 &&
            time !== "00" &&
            time !== "01" &&
            time !== "02" &&
            time !== "03" &&
            time !== "04" &&
            time !== "05" &&
            time !== "06" &&
            time !== "07" &&
            time !== "08" &&
            time !== "09"
              ? `0${time}`
              : time
          }:${
            Number(MM) < 10 &&
            MM !== "00" &&
            MM !== "01" &&
            MM !== "02" &&
            MM !== "03" &&
            MM !== "04" &&
            MM !== "05" &&
            MM !== "06" &&
            MM !== "07" &&
            MM !== "08" &&
            MM !== "09"
              ? `0${MM}`
              : MM
          }:00`;
        }
        await adminRequestPost(`/api/match/connect`, body);
        alert("연결 성공 하였습니다.");
        setConnectID("");
        setDate("");
        setTime("");
        setPlace("");
        setEtc("");
        setMemo("");
        setManager("");
        setConnectModal(false);
        window.location.href = "/like/match/user/list";
      } catch (error) {
        alert(error?.data?.error);
      }
  };

  const onClose = () => {
    setConnectID("");
    setDate("");
    setTime("");
    setPlace("");
    setEtc("");
    setMemo("");
    setManager("");
    setConnectModal(false);
  };

  const changeHour = (e) => {
    if (
      Number(e.target.value) <= 24 &&
      Number(e.target.value) >= 0 &&
      e.target.value.length <= 2
    ) {
      return setTime(e.target.value);
    } else {
      return;
    }
  };

  const changeMM = (e) => {
    if (
      Number(e.target.value) <= 60 &&
      Number(e.target.value) >= 0 &&
      e.target.value.length <= 2
    ) {
      return setMM(e.target.value);
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="5">
            <div className="modalOverlay" />
            <Card
              className="modalCard pt-3 ps-3 pe-2"
              style={{ position: "relative" }}
            >
              <h2>연결</h2>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      연결 담당 매니저
                    </label>
                    <select
                      id="counselor_genderLabel"
                      className="form-select"
                      value={manager || ""}
                      onChange={(e) => setManager(e.target.value)}
                    >
                      {!manager ? (
                        <option value="">선택해주세요.</option>
                      ) : null}
                      <option value="김보연">김보연</option>
                      <option value="임유경">임유경</option>
                      <option value="오예린">오예린</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      날짜
                    </label>
                    <input
                      type="date"
                      className="js-input-mask form-control customInputLeft"
                      onChange={(e) => setDate(e.target.value)}
                      max="9999-12-31"
                      min={moment().format("YYYY-MM-DD")}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      시간
                    </label>
                    <div className="d-flex align-items-center">
                      <input
                        type="number"
                        className="js-input-mask form-control customInput"
                        value={time}
                        onWheel={(e) => e.target.blur()}
                        onChange={changeHour}
                        style={{
                          width: "44px",
                          padding: "0.47rem 0px 0.47rem 0.75rem",
                          textAlign: "end",
                        }}
                      />
                      <label className="textLabel mx-2 my-0">시</label>
                      <input
                        type="number"
                        className="js-input-mask form-control customInput"
                        value={MM}
                        onWheel={(e) => e.target.blur()}
                        onChange={changeMM}
                        style={{
                          width: "44px",
                          padding: "0.47rem 0px 0.47rem 0.75rem",
                          textAlign: "end",
                        }}
                      />
                      <label className="textLabel mx-2 my-0">분</label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      장소
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInputLeft"
                      onChange={(e) => setPlace(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      기타 사항
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInputLeft"
                      onChange={(e) => setEtc(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="rowBorderBottom w-100 px-0  pt-3 pb-3">
                <Col lg={12} className="d-flex flex-column">
                  <div>
                    <label htmlFor={`statusLabel`} className="labelText">
                      내부 메모
                    </label>
                    <input
                      type="text"
                      className="js-input-mask form-control customInputLeft"
                      onChange={(e) => setMemo(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row
                className="w-100 px-0 pt-3 pb-3"
                style={{
                  borderTop: "1px solid #ced4da",
                  backgroundColor: "#fff",
                  position: "sticky",
                  bottom: "0",
                }}
              >
                <Col className="text-end">
                  <Button
                    id="masterAccesBtn"
                    color="danger"
                    style={{ marginRight: "10px" }}
                    onClick={() => onClose()}
                  >
                    닫기
                  </Button>
                  <Button
                    id="masterAccesBtn"
                    color="primary"
                    onClick={() => callConnect()}
                  >
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ConnectModal;
