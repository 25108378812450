import React from "react";
import { Col, FormGroup, Row } from "reactstrap";
function Adress({ editData }) {
  return (
    <React.Fragment>
      <h2>주소 정보</h2>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      <Row className="rowBorderBottom mt-3">
        <h4>본인거주지</h4>
        <Col md="10">
          <FormGroup className="mb-3">
            <div>{editData?.address[0]?.name}</div>
          </FormGroup>
        </Col>
        <Col md="10">
          <FormGroup className="mb-3">
            <div>{editData?.address[0]?.detail}</div>
          </FormGroup>
        </Col>
        <Col md="10">
          <FormGroup className="mb-3">
            <div>{editData?.address[0]?.address}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <h4>부모거주지</h4>
        <Col md="10">
          <FormGroup className="mb-3">
            <div>{editData?.address[1]?.name}</div>
          </FormGroup>
        </Col>
        <Col md="10">
          <FormGroup className="mb-3">
            <div>{editData?.address[1]?.detail}</div>
          </FormGroup>
        </Col>
        <Col md="10">
          <FormGroup className="mb-3">
            <div>{editData?.address[1]?.address}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <h4>직장거주지</h4>
        <Col md="10">
          <FormGroup className="mb-3">
            <div>{editData?.address[2]?.name}</div>
          </FormGroup>
        </Col>
        <Col md="10">
          <FormGroup className="mb-3">
            <div>{editData?.address[2]?.detail}</div>
          </FormGroup>
        </Col>
        <Col md="10">
          <FormGroup className="mb-3">
            <div>{editData?.address[2]?.address}</div>
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Adress;
