import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

function UserInfo({ editData }) {
  return (
    <React.Fragment>
      <h2>유저 정보</h2>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      <Row className="rowBorderBottom mt-3">
        <Col md="10" className="mb-3">
          <Label htmlFor="validationCustom03">이름</Label>
          <div>{editData?.name}</div>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom05">성별</Label>
            <div>{editData?.gender}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">주민등록번호</Label>
            <div>{editData?.ssn}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">연락처</Label>
            <div>{editData?.phone}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">출생연월일</Label>
            <div>{editData?.birthday}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">결혼유무</Label>
            <div>{editData?.isMarry ? "유" : "무"}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">자택전화</Label>
            <div>{editData?.homeTell}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">직장전화</Label>
            <div>{editData?.jobTell}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">이메일</Label>
            <div>{editData?.email}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">상담매니저ID</Label>
            <div>{editData?.counselorId}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">추천 코드</Label>
            <div>{editData?.comment}</div>
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default UserInfo;
