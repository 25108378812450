import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

function Company({ handleChange, attributes }) {
  return (
    <React.Fragment>
      <h2>직장정보</h2>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      <Row className="rowBorderBottom mt-3">
        <h4>현직장</h4>
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom03">직장명</Label>
            <Input
              name="nowconame"
              placeholder="직장명을 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom03"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="12">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom05">업종</Label>
            <Row style={{ flexFlow: "wrap" }}>
              {attributes?.industry?.value?.map((item, i) => {
                return (
                  <Col
                    sm="6"
                    className="d-flex"
                    key={i}
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        name="nowcoindustry"
                        value={item.id}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onChange={handleChange}
                      />
                    </div>
                    <label className="m-0">{item.value}</label>
                  </Col>
                );
              })}
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">직원수</Label>
            <Input
              name="nowcoemployeeNumber"
              placeholder="직원수를 입력해 주세요."
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">부서</Label>
            <Input
              name="nowcodepartment"
              placeholder="부서를 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">직위</Label>
            <Input
              name="nowcoposition"
              placeholder="직위를 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">담당업무</Label>
            <Input
              name="nowcoduties"
              placeholder="담당업무를 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">입사월년</Label>
            <Input
              name="nowcojoinYearMonth"
              placeholder="입사월년을 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">고용형태</Label>
            <Input
              name="nowcoemploymentType"
              placeholder="고용형태를 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">현재다니는지 여부</Label>
            <div className="d-flex">
              <input
                type="radio"
                name="nowcocurrent"
                value={true}
                style={{ width: "20px", height: "20px", marginRight: "5px" }}
                onChange={handleChange}
              />
              <label>재직중</label>
              <input
                type="radio"
                name="nowcocurrent"
                value={false}
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "20px",
                  marginRight: "5px",
                }}
                onChange={handleChange}
              />
              <label>휴직중</label>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">연수입</Label>
            <Input
              name="nowcoannualIncome"
              placeholder="연수입을 입력해 주세요."
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">기타수입</Label>
            <Input
              name="nowcoortherIncome"
              placeholder="기타수입을 입력해 주세요."
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-1">
            <Label htmlFor="validationCustom04">주소</Label>
            <Input
              name="nowcoaddress"
              placeholder="주소"
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md="10">
          <FormGroup className="mb-1">
            <Input
              name="nowcodetail"
              placeholder="상세주소"
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md="10">
          <FormGroup className="mb-3">
            <Input
              name="nowcopostNum"
              placeholder="우편번호"
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <h4>전직장</h4>
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom03">직장명</Label>
            <Input
              name="cuconame"
              placeholder="직장명을 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom03"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom05">업종</Label>
            <Row style={{ flexFlow: "wrap" }}>
              {attributes?.industry?.value?.map((item, i) => {
                return (
                  <Col
                    sm="6"
                    className="d-flex"
                    key={i}
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        name="cucoindustry"
                        value={item.id}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                        onChange={handleChange}
                      />
                    </div>
                    <label className="m-0">{item.value}</label>
                  </Col>
                );
              })}
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">직원수</Label>
            <Input
              name="cucoemployeeNumber"
              placeholder="직원수를 입력해 주세요."
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">부서</Label>
            <Input
              name="cucodepartment"
              placeholder="부서를 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">직위</Label>
            <Input
              name="cucoposition"
              placeholder="직위를 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">담당업무</Label>
            <Input
              name="cucoduties"
              placeholder="담당업무를 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">입사월년</Label>
            <Input
              name="cucojoinYearMonth"
              placeholder="입사월년을 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">고용형태</Label>
            <Input
              name="cucoemploymentType"
              placeholder="고용형태를 입력해 주세요."
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">현재다니는지 여부</Label>
            <div className="d-flex">
              <input
                type="radio"
                name="cucocurrent"
                value={true}
                style={{ width: "20px", height: "20px", marginRight: "5px" }}
                onChange={handleChange}
              />
              <label>재직중</label>
              <input
                type="radio"
                name="cucocurrent"
                value={false}
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "20px",
                  marginRight: "5px",
                }}
                onChange={handleChange}
              />
              <label>휴직중</label>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">연수입</Label>
            <Input
              name="cucoannualIncome"
              placeholder="연수입을 입력해 주세요."
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">기타수입</Label>
            <Input
              name="cucoortherIncome"
              placeholder="기타수입을 입력해 주세요."
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-1">
            <Label htmlFor="validationCustom04">주소</Label>
            <Input
              name="cucoaddress"
              placeholder="주소"
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md="10">
          <FormGroup className="mb-1">
            <Input
              name="cucodetail"
              placeholder="상세주소"
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md="10">
          <FormGroup className="mb-3">
            <Input
              name="cucopostNum"
              placeholder="우편번호"
              type="text"
              className="form-control"
              id="validationCustom04"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Company;
