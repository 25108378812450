import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

function Sibling({ editData }) {
  return (
    <React.Fragment>
      <h2>형제 관계</h2>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom03">형제관계</Label>
            <div>{editData?.brotherRelation}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="rowBorderBottom mt-3">
        <Col md="10">
          <FormGroup className="mb-3">
            <Label htmlFor="validationCustom04">장남,장녀 여부</Label>
            <div>??</div>
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Sibling;
