import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLikeHistoryListStore } from "../../store/zustand/state";
import { useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Button } from "reactstrap";

import { adminUserPut, requestDelete, requestGet } from "../../apis/request";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import ToolkitProvider from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Tables/datatables.scss";
import "../Consult/consult.scss";
import PaginationAPIWrap from "../../components/PaginationAPIWrap";
import moment from "moment";
import MobilePaginationAPIWrap from "../../components/MobilePaginationAPIWrap";

function LikeHistory(props) {
  const [memberList, setMemberList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [pageNumber, setpageNumber] = useState([]);
  const [mobilepageNumber, setMobilepageNumber] = useState([]);
  const [activeNum, setActiveNum] = useState(null);
  const [lastpage, setLastpage] = useState(null);
  const [username, setuserName] = useState("");
  const [usergender, setuserGender] = useState("");
  const [totalCount, setTotalCount] = useState(null);

  const [tableHeight, setTableHeight] = useState(0);
  const topBarref = useRef(null);
  const searchref = useRef(null);
  const btnref = useRef(null);
  const history = useHistory();
  const { list, updateList, updateActiveNum } = useLikeHistoryListStore();

  const columns = [
    {
      dataField: "name",
      text: "이름",
      sort: true,
    },
    {
      dataField: "change",
      text: "사용량",
      sort: true,
    },
    {
      dataField: "type",
      text: "타입",
      sort: true,
    },
    {
      dataField: "because",
      text: "사유",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "사용 시간",
      sort: true,
    },
    {
      dataField: "action",
      text: "변경",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div className="d-flex">
            <select
              className="form-select customInput w-auto"
              name="joinFrom"
              value={row.types}
              onChange={(e) => updateTypeById(row.id, e.target.value)}
            >
              <option value={"무료"}>무료</option>
              <option value={"유료"}>유료</option>
              <option value={"얼리버드"}>얼리버드</option>
            </select>
            <Button
              color="primary"
              className="ms-2"
              onClick={() => updateType(row.id, row.types)}
            >
              변경
            </Button>
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const updateTypeById = (id, newType) => {
    setProductData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, types: newType } : item
      )
    );
  };

  const updateType = async (id, types) => {
    if (window.confirm("타입을 변경하시겠습니까?")) {
      try {
        let body = {
          id: id,
          type:
            types === "유료"
              ? "finalLikeCoupon"
              : types === "무료"
              ? "finalFreeLikeCoupon"
              : types === "얼리버드"
              ? "earlybirdLikeCoupon"
              : "",
        };
        await adminUserPut(`/api/cash/history/change/type`, body);
        alert("변경 완료 하였습니다.");
        callInfo();
      } catch (error) {
        console.log(error);
        alert(error.data.message);
      }
    }
  };

  const callInfo = useCallback(async () => {
    try {
      const params = new URLSearchParams();
      if (list) {
        for (var key in list) {
          if (list[key]) {
            params.append(key, list[key]);
          }
        }
      }
      const memberResponse = await requestGet(
        `/api/cash/history/list/like/final?${params}`
      );
      setMemberList(memberResponse?.data);
      setLastpage(memberResponse?.totalPage);
      setTotalCount(memberResponse?.totalCount);
    } catch (error) {
      console.log(error);
    }
  }, [list]);

  useEffect(() => {
    setuserName(list.name);
    setuserGender(list.counselor_gender);
    setActiveNum(list.page);
    callInfo();
  }, [callInfo, list]);

  const gohistory = () => {
    updateList(username, usergender, 1);
  };

  useEffect(() => {
    if (memberList?.length > 0) {
      let arr = [];
      memberList.forEach((item) =>
        arr.push({
          id: item.id,
          name: item.name,
          change: item.change,
          type:
            item.type === "likeCoupon" || item.type === "finalLikeCoupon"
              ? "유료"
              : item.type === "finalFreeLikeCoupon" ||
                item.type === "freeLikeCoupon"
              ? "무료"
              : item.type === "earlybirdLikeCoupon"
              ? "얼리버드"
              : item.type === "subscribe"
              ? "기간제"
              : "",
          types:
            item.type === "likeCoupon" || item.type === "finalLikeCoupon"
              ? "유료"
              : item.type === "finalFreeLikeCoupon" ||
                item.type === "freeLikeCoupon"
              ? "무료"
              : item.type === "earlybirdLikeCoupon"
              ? "얼리버드"
              : item.type === "subscribe"
              ? "기간제"
              : "",
          because: item.because,
          createdAt: item.createdAt,
        })
      );
      setProductData(arr);
    } else {
      setProductData([]);
    }
  }, [memberList]);

  const handlename = (e) => {
    setuserName(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      gohistory();
    }
  };

  const genderChange = (e) => {
    setuserGender(e.target.value);
  };

  useEffect(() => {
    const updateTableHeight = () => {
      const topBarHeight = topBarref.current
        ? topBarref.current.offsetHeight
        : 0;
      const searchHeight = searchref.current
        ? searchref.current.offsetHeight
        : 0;
      const btnHeight = btnref.current ? btnref.current.offsetHeight : 0;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const newHeight = windowHeight - searchHeight - btnHeight - 150; // 여유 공간 추가
      if (windowWidth <= 992) {
        setTableHeight("none");
      } else if (windowWidth > 992) {
        setTableHeight(newHeight + "px");
      }
    };
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [topBarref, searchref, btnref, lastpage, productData]);

  return (
    <React.Fragment>
      <div className="custompage-content">
        <MetaTags>
          <title>매칭권 사용 목록</title>
        </MetaTags>
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={productData}
                    search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <div ref={searchref}>
                          <h2 style={{ paddingBottom: "24px" }}>
                            매칭권 사용 목록
                          </h2>
                        </div>
                        <div
                          className="customScroll"
                          style={{
                            maxHeight: tableHeight,
                            overflow: "auto",
                          }}
                        >
                          <div className="text-end customTotalcount">
                            검색결과 (
                            {String(totalCount).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                            개의 검색결과가 있습니다)
                          </div>
                          <Row>
                            <Col xl="12">
                              <div
                                className="table-responsive customtable"
                                style={{ overflowX: "unset" }}
                              >
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap tbnom"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div ref={btnref} style={{ height: "auto" }}>
                          {!lastpage ? null : (
                            <Row className="w-100 m-0 p-0 py-3">
                              <Col className="w-100 m-0 p-0 d-none d-lg-flex">
                                <PaginationAPIWrap
                                  pageNumber={pageNumber}
                                  setpageNumber={setpageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                              <Col className="w-100 m-0 p-0 d-flex d-lg-none">
                                <MobilePaginationAPIWrap
                                  pageNumber={mobilepageNumber}
                                  setpageNumber={setMobilepageNumber}
                                  lastpage={lastpage}
                                  activeNum={activeNum}
                                  setActiveNum={updateActiveNum}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LikeHistory;
