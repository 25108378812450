import React from "react";

import { useLocation } from "react-router-dom";
import { ReactComponent as Xmark } from "../../assets/icon/svgs/solid/xmark.svg";

function ImgDetail(props) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const url = params.get("src");
  return (
    <React.Fragment>
      <div>
        {url ? (
          <img
            src={decodeURIComponent(url)}
            alt="imgDetail"
            style={{ width: "100%" }}
          />
        ) : (
          <p>No image URL provided</p>
        )}
        <Xmark
          style={{
            position: "fixed",
            top: "0",
            right: "0",
            backgroundColor: "rgba(204, 204, 204, 0.5)",
          }}
          width={50}
          height={50}
          onClick={() => window.close()}
        />
        <button
          className="introCustomRejectButton"
          style={{
            position: "fixed",
            bottom: "0",
            right: "0",
            margin: "10px",
            width: "auto",
            padding: "5px 20px",
          }}
          onClick={() => window.close()}
        >
          닫기
        </button>
      </div>
    </React.Fragment>
  );
}

export default ImgDetail;
