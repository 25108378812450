import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

import { adminUserPut, requestGet } from "../../apis/request";

function AuthPage({ id, authData }) {
  const [isVerify, setIsVerify] = useState(false);
  const [univ, setUniv] = useState(false);
  const [hasJob, setHasJob] = useState(false);
  const [yearIncome, setYearIncome] = useState(false);
  const [asset, setAsset] = useState(false);
  const [yearIncome2, setYearIncome2] = useState(false);
  const [img, setImg] = useState(false);
  const [selfIntro, setSelfIntro] = useState(false);
  const [extra, setExtra] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (authData) {
      setAsset(authData.asset);
      setExtra(authData.extra);
      setIsVerify(authData.idVerify);
      setHasJob(authData.hasJob);
      setImg(authData.img);
      setSelfIntro(authData.selfIntro);
      setUniv(authData.univ);
      setYearIncome(authData.yearIncome);
      setYearIncome2(authData.yearIncome2);
    }
  }, [authData]);

  const onClickSendPurchase = async () => {
    if (window.confirm("인증 수정 하시겠습니까?")) {
      try {
        let body = {
          profileId: id,
          idVerify: isVerify,
          univ: univ,
          hasJob: hasJob,
          yearIncome: yearIncome,
          asset: asset,
          yearIncome2: yearIncome2,
          img: img,
          selfIntro: selfIntro,
          extra: extra,
        };
        await adminUserPut(`/api/profile/auth/update`, body);
        alert("인증 수정 하였습니다.");
        window.location.reload();
      } catch (error) {
        console.log(error);
        alert("인증 수정 실패 하였습니다.");
      }
    }
  };

  return (
    <React.Fragment>
      <h3 className="MainText pt-3">인증 관련</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom" />
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label className="labelText">신분증</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <input
                  type="checkbox"
                  id={`passLabel`}
                  checked={isVerify}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  onClick={(e) => setIsVerify(!isVerify)}
                />
                <label htmlFor={`passLabel`} className="textLabel m-0">
                  인증
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label className="labelText">대학교</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <input
                  type="checkbox"
                  id={`schoolLabel`}
                  checked={univ}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  onClick={(e) => setUniv(!univ)}
                />
                <label htmlFor={`schoolLabel`} className="textLabel m-0">
                  인증
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label className="labelText">재직</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <input
                  type="checkbox"
                  id={`hasJobLabel`}
                  checked={hasJob}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  onClick={(e) => setHasJob(!hasJob)}
                />
                <label htmlFor={`hasJobLabel`} className="textLabel m-0">
                  인증
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label className="labelText">연봉</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <input
                  type="checkbox"
                  id={`yearMoneyLabel`}
                  checked={yearIncome}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  onClick={(e) => setYearIncome(!yearIncome)}
                />
                <label htmlFor={`yearMoneyLabel`} className="textLabel m-0">
                  인증
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label className="labelText">개인 재산</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <input
                  type="checkbox"
                  id={`myMoneyLabel`}
                  checked={asset}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  onClick={(e) => setAsset(!asset)}
                />
                <label htmlFor={`myMoneyLabel`} className="textLabel m-0">
                  인증
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label className="labelText">부모님 재산</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <input
                  type="checkbox"
                  id={`myYearMoneyLabel`}
                  checked={yearIncome2}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  onClick={(e) => setYearIncome2(!yearIncome2)}
                />
                <label htmlFor={`myYearMoneyLabel`} className="textLabel m-0">
                  인증
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label className="labelText">사진</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <input
                  type="checkbox"
                  id={`photoLabel`}
                  checked={img}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  onClick={(e) => setImg(!img)}
                />
                <label htmlFor={`photoLabel`} className="textLabel m-0">
                  인증
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label className="labelText">자기소개</label>
            <div className="d-flex pt-2 radiowrap">
              <div className="d-flex mr20 mb-2">
                <input
                  type="checkbox"
                  id={`selfIntroLabel`}
                  checked={selfIntro}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  onClick={(e) => setSelfIntro(!selfIntro)}
                />
                <label htmlFor={`selfIntroLabel`} className="textLabel m-0">
                  인증
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="rowBorderBottom pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <label htmlFor={`etcLabel`} className="labelText">
              비고
            </label>
            <textarea
              rows={5}
              className="js-input-mask form-control customInput"
              value={extra}
              id={`etcLabel`}
              placeholder={`비고를 입력해 주세요.`}
              aria-label={`비고를 입력해 주세요.`}
              onChange={(e) => setExtra(e.target.value)}
              style={{ resize: "none" }}
            />
          </div>
        </Col>
      </Row>
      <Row className="pt-3 pb-3">
        <Col lg={12} className="d-flex flex-column">
          <div>
            <Button
              onClick={() => onClickSendPurchase()}
              color="primary"
              style={{ marginTop: "6px", marginRight: "10px" }}
            >
              보내기
            </Button>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default AuthPage;
